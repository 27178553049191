import React, { useEffect, useState } from 'react'
import { useGlobals } from '../mgt/GlobalsProvider'
import { tagBackgroundColor, tagBorderColor, tagColor } from '../mgt/constants'
import { JiraIcon, TagHoleCircleIcon } from './Icons'
import { remToPixels } from '../mgt/layoutMgt'
import * as jiraAPI from '../mgt/jiraAPI'
import * as timeLogifyAPI from '../mgt/timeLogifyAPI'
import { convertTimestampToDate } from '../mgt/timeMgt'

const JiraLogWorkPopupContent = () => {
    const [jiraIssues, setJiraIssues] = useState([])
    const [issueTimeSpent, setIssueTimeSpent] = useState({})
    const [loadingTimeSpent, setLoadingTimeSpent] = useState(true)
    const oneRemToPixels = remToPixels(1)
    const [addingWorkLog, setAddingWorkLog] = useState(false)
    const {
        userDoc,
        filteredLogs: logs,
    } = useGlobals()


    useEffect(() => {
        if (!logs || !userDoc) return

        getSetJiraIssues(logs)
    }, [logs, userDoc])

    function getJiraTag(log) {
        return log?.tags?.find(tag => tag.category === userDoc.settings.integrations.jira.tagCategory)
    }

    function getTimeSpent(issueKey, logs) {
        // 15 minutes for each log with the Jira tag
        return logs
            .filter(log => getJiraTag(log) && getJiraTag(log).value === issueKey)
            .reduce((acc, _) => acc + 15, 0)
    }

    async function getSetJiraIssues(logs) {
        const jiraIssues = logs
            .filter(log => getJiraTag(log))
            .map(log => {
                return {
                    key: getJiraTag(log).value,
                    text: log.title,
                }
            })
            .filter((issue, i, self) => self.findIndex(t => t.key === issue.key) === i)
            .map(issue => {
                return {
                    key: issue.key,
                    timeSpent: getTimeSpent(issue.key, logs),
                    text: issue.text,
                }
            })

        const date = convertTimestampToDate(logs[0].startTime)

        handleGetWorkLog(jiraIssues, date)

        setJiraIssues(jiraIssues)
    }

    function getTagColor() {
        return userDoc.settings.tags.find(tag => tag.category === userDoc.settings.integrations.jira.tagCategory).color
    }

    function formatTimeSpent(timeSpent) {
        // Format: 1d 2h 3m
        const days = Math.floor(timeSpent / 480)
        const hours = Math.floor((timeSpent % 480) / 60)
        const minutes = timeSpent % 60
        let str = ''

        if (days > 0) str += `${days}d`
        if (hours > 0) str += ` ${hours}h`
        if (minutes > 0) str += ` ${minutes}m`

        if (str === '') str = '0m'

        return str
    }

    async function handleGetWorkLog(jiraIssues, date) {
        const result = await jiraAPI.getIssueTimeSpent(jiraIssues.map(issue => issue.key), userDoc, date)
        setIssueTimeSpent(result)
        setLoadingTimeSpent(false)
    }

    async function logWorkToJira(issue) {
        if (addingWorkLog) return
        setAddingWorkLog(true)

        const issueKey = issue.key
        const date = convertTimestampToDate(getStartOfJira(issue))
        const timeSpent = formatTimeSpent(issue.timeSpent - (issueTimeSpent[issueKey]))

        const result = await timeLogifyAPI.logWorkToJira(issueKey, userDoc, date, timeSpent)
        if (result) {
            console.log('Logged work to Jira:', issueKey, timeSpent)
            const newIssueTimeSpent = { ...issueTimeSpent }
            newIssueTimeSpent[issueKey] = issue.timeSpent
            setIssueTimeSpent(newIssueTimeSpent)
        } else {
            console.log('Failed to log work to Jira:', issueKey, timeSpent)
        }

        setAddingWorkLog(false)
    }

    function getStartOfJira(issue) {
        return logs
            .sort((a, b) => a.startTime.seconds - b.startTime.seconds)
            .find(log => getJiraTag(log) && getJiraTag(log).value === issue.key).startTime
    }

    return <>
        {jiraIssues.map((issue, i) => {
            const jiraUrl = userDoc.settings.integrations.jira.issueBaseUrl + 'browse/' + issue.key

            return (
                <div className='jiraLogWorkIssueRow' key={i}>
                    <div style={{ minWidth: '10rem' }}>
                        <div
                            className='settingsTagCategoryName'
                            title={jiraUrl}
                            style={{
                                backgroundColor: tagBackgroundColor(getTagColor()),
                                border: '1px solid ' + tagBorderColor(getTagColor()),
                                color: tagColor(getTagColor()),
                                cursor: 'pointer',
                            }}
                            onClick={() => window.open(jiraUrl, '_blank')}
                        >
                            <TagHoleCircleIcon
                                size={oneRemToPixels / 1.5}
                                color={tagColor(getTagColor())}
                            />
                            {issue.key}
                        </div>
                    </div>
                    <div style={{ width: '11rem', textAlign: 'left' }}>
                        <span style={{ color: 'var(--text-color-subtle)' }}>Time spent </span>
                        {formatTimeSpent(issue.timeSpent)}
                    </div>
                    <div style={{ width: '9rem', textAlign: 'left' }}>
                        <span style={{ color: 'var(--text-color-subtle)' }}>{loadingTimeSpent ? 'Calculating...' : 'Logged '}</span>
                        {!loadingTimeSpent && formatTimeSpent(issueTimeSpent[issue.key])}
                    </div>
                    <div style={{ width: '10rem', textAlign: 'left' }}>
                        <span style={{ color: 'var(--text-color-subtle)' }}>{loadingTimeSpent ? 'Calculating...' : 'Remaining '}</span>
                        {!loadingTimeSpent && formatTimeSpent(issue.timeSpent - (issueTimeSpent[issue.key]))}
                    </div>
                    {!loadingTimeSpent && issue.timeSpent - (issueTimeSpent[issue.key]) > 0 ?
                        <div
                            className={'growOnHover'}
                            title={'Log' + formatTimeSpent(issue.timeSpent - (issueTimeSpent[issue.key])) + ' to ' + issue.key}
                            style={{ marginLeft: 'auto', position: 'relative' }}
                            onClick={() => logWorkToJira(issue)}
                        >
                            <div className='hoverPopup'>
                                Log {formatTimeSpent(issue.timeSpent - (issueTimeSpent[issue.key]))} to {issue.key}
                            </div>
                            <JiraIcon size={oneRemToPixels * 1.5} />
                        </div>
                        :
                        <div
                            style={{ marginLeft: 'auto', filter: 'grayscale(1)' }}
                        >
                            <JiraIcon size={oneRemToPixels * 1.5} />
                        </div>
                    }
                </div>
            )
        })}
    </>
}

export default JiraLogWorkPopupContent