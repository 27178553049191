// Imports:
import { auth, db } from './firebase'
import { doc, getDoc, setDoc, getDocs, Timestamp, collection, where, query, writeBatch } from "firebase/firestore"
import { getLocalStorageLogs, getLocalStorageUser, getLocalStorageUserComputers, setLocalStorageLogs, setLocalStorageUser, setLocalStorageUserComputers } from './cachingMgt'
import { defaultScheduleSettings } from './constants'
import { convertTimestampToDate, getDayKeyFromDate } from './timeMgt'
import * as timeLogifyAPI from './timeLogifyAPI'

// Firestore functions:
const emptyUserDocument = (userId, email, name) => {
    return {
        id: userId,
        email: email,
        name: name,
        dateCreated: new Date().getTime(),
        upgradeTags: [],
        settings: {
            schedule: defaultScheduleSettings,
            tags: [],
            rules: []
        }
    }
}

async function createUserDocument(userId, email, name) {
    console.log('[FIREBASE] Creating user doc:', userId)
    try {
        const newUserDoc = emptyUserDocument(userId, email, name)
        const userDocRef = doc(db, "users", userId)
        await setDoc(userDocRef, newUserDoc)

        return newUserDoc
    } catch (error) {
        console.log('Error creating user doc:', error)
        return false
    }
}

async function checkMissingSettings(user) {
    let updateNeeded = false

    if (!user.settings) {
        user.settings = {
            schedule: defaultScheduleSettings
        }
        updateNeeded = true
    }
    if (!user.settings.schedule) {
        user.settings.schedule = user.settings.workingHours || defaultScheduleSettings
        updateNeeded = true
    }
    if (!user.settings.tags) {
        user.settings.tags = [
            'Project',
            'Task',
            'Type',
        ]
        updateNeeded = true
    }
    if (user?.settings?.workingHours) {
        delete user.settings.workingHours
        updateNeeded = true
    }
    if (!user?.settings?.rules) {
        user.settings.rules = []
        updateNeeded = true
    }
    if (!user?.settings?.general) {
        user.settings.general = {
            autoStart: false,
        }
        updateNeeded = true
    }
    if (!user?.settings?.integrations) {
        user.settings.integrations = {
            jira: {
                enabled: false,
                tagCategory: '',
                issueBaseUrl: '',
            },
            excel: {
                enabled: false,
                templates: []
            }
        }
        updateNeeded = true
    }

    if (!user.settings?.integrations?.jira?.email) {
        user.settings.integrations.jira.email = ''
        updateNeeded = true
    }
    if (!user.settings?.integrations?.jira?.token) {
        user.settings.integrations.jira.token = ''
        updateNeeded = true
    }

    if (updateNeeded) modifyUserDocument(user)
}

async function getUserDocument(userId, customDisplayName) {
    try {
        const user = await getLocalStorageUser()
        if (user) {
            await checkMissingSettings(user)
            return user
        }

        console.log('[FIREBASE] Getting user doc:', userId)
        const pointer = doc(db, "users", userId)
        const snapshot = await getDoc(pointer)
        const name = customDisplayName ? customDisplayName : auth.currentUser.displayName
        const docData = snapshot.exists() ? snapshot.data() : await createUserDocument(userId, auth.currentUser.email, name)
        await checkMissingSettings(docData)
        setLocalStorageUser(docData)
        return docData

    } catch (error) {
        console.log('Error getting user doc:', error)
        return false
    }
}

async function modifyUserDocument(userDoc) {
    try {
        console.log('[FIREBASE] Updating user doc:', userDoc.id)
        const pointer = doc(db, "users", userDoc.id)
        await setDoc(pointer, userDoc)
        setLocalStorageUser(userDoc)
        return userDoc
    } catch (error) {
        console.log('Error updating user doc:', error)
        return false
    }
}

async function createUserComputerDocument(userId, hostId, hostName) {
    try {
        const computers = await getUserComputerDocuments(userId)

        const existingComputerDoc = computers.find(computer => computer.hostId === hostId)
        if (existingComputerDoc) {
            console.log('Computer already exists:', existingComputerDoc)
            return existingComputerDoc
        }

        const newComputerDoc = {
            hostId: hostId,
            hostName: hostName,
            dateCreated: Timestamp.now()
        }

        console.log('[FIREBASE] Creating user computer doc. User:', userId, 'Computer:', hostId)

        const pointer = doc(db, "users", userId, "computers", hostId)
        await setDoc(pointer, newComputerDoc)

        console.log('Existing computers:', computers)


        if (existingComputerDoc) {
            computers.push(newComputerDoc)
            setLocalStorageUserComputers(computers)
            console.log('New computer added:', newComputerDoc)
        }

        return newComputerDoc
    } catch (error) {
        console.log('Error creating user computer doc:', error)
        return false
    }
}

async function getUserComputerDocuments(userId) {
    try {
        const userComputers = await getLocalStorageUserComputers()
        if (userComputers) {
            return userComputers
        }

        console.log('[FIREBASE] Getting user computer docs for:', userId)

        const pointer = collection(doc(db, "users", userId), "computers");
        const snapshot = await getDocs(pointer)
        const computers = []
        snapshot.forEach(doc => {
            computers.push(doc.data())
        })

        setLocalStorageUserComputers(computers)
        return computers
    } catch (error) {
        console.log('Error getting user computer docs:', error)
        return false
    }
}

async function getUserComputerDocument(userId, hostId) {
    try {
        console.log('[FIREBASE] Getting user computer doc:', userId, hostId)
        const pointer = doc(db, "users", userId, "computers", hostId)
        const snapshot = await getDoc(pointer)
        console.log('Snapshot:', snapshot)
        console.log('Snapshot exists:', snapshot.exists())
        console.log('Snapshot data:', snapshot.data())
        const computer = snapshot.exists() ? snapshot.data() : false
        return computer
    } catch (error) {
        console.log('Error getting user computer doc:', error)
        return false
    }
}

async function updateUserComputerDocument(userId, hostId, newHostName) {
    try {
        console.log('[FIREBASE] Updating user computer doc:', userId, hostId)
        const pointer = doc(db, "users", userId, "computers", hostId)
        await setDoc(pointer, { hostName: newHostName }, { merge: true })

        const computers = await getUserComputerDocuments(userId)
        const updatedComputer = computers.find(computer => computer.hostId === hostId)
        updatedComputer.hostName = newHostName

        setLocalStorageUserComputers(computers)
        return updatedComputer
    } catch (error) {
        console.log('Error updating user computer doc:', error)
        return false
    }
}

async function getUserComputerLogs({ userId, hostId }) {
    try {
        const today = new Date()
        const fromDay = new Date(today)
        fromDay.setDate(fromDay.getDate() - 8)
        const firstDayOfWeek = new Date(fromDay)
        firstDayOfWeek.setDate(1 + (firstDayOfWeek.getDate() - firstDayOfWeek.getDay()))
        const startDate = getDayKeyFromDate(firstDayOfWeek)
        const endDate = getDayKeyFromDate(today)

        const logs = await getLogsFromFirestore({ userId, hostId, startDate, endDate })

        // Get new logs from local sqlite
        const unsyncedLogs = await timeLogifyAPI.getUnsyncedLogs()
        if (unsyncedLogs.length > 0) {
            console.log('Unsynced logs:', unsyncedLogs)
            let updateNeeded = false

            if (logs.length > 0) {
                updateNeeded = true
                const lastFirestoreLog = logs[logs.length - 1]

                if (lastFirestoreLog.startTime.seconds === unsyncedLogs[0].startTime.seconds) {
                    logs.pop()

                    if (lastFirestoreLog.logs.length === unsyncedLogs[0].logs.length && unsyncedLogs.length === 1) {
                        console.log('No new logs to sync')
                        updateNeeded = false
                    }
                }

                if (updateNeeded) {
                // Add unsynced logs to Firestore
                    await syncLogsToFirestore({ userId, hostId, logs: unsyncedLogs })
                    timeLogifyAPI.setLogsSynced(unsyncedLogs[unsyncedLogs.length - 1].startTime)
                }
            }

            // Add unsynced logs to local logs
            logs.push(...unsyncedLogs)
        }

        // Save logs to local storage
        setLocalStorageLogs({ hostId, userId, logs: logs })

        console.log('All logs:', logs)

        return logs
    } catch (error) {
        console.log('Error getting logs:', error)
        return []
    }
}

async function syncLogsToFirestore({ userId, hostId, logs }) { // Logs = [{ startTime, logs: [{ timestamp, title }] }]
    try {
        console.log('[Firestore] Syncing logs for user:', userId, 'computer:', hostId)
        console.log('Logs to sync:', logs)
        const logsPerDay = {}
        logs.forEach(interval => {
            const dayKey = getDayKeyFromDate(convertTimestampToDate(interval.startTime))
            if (!logsPerDay[dayKey]) logsPerDay[dayKey] = []
            logsPerDay[dayKey].push(interval)
        })
        console.log('Logs to sync per day:', logsPerDay)

        const batch = writeBatch(db)
        const pointer = collection(db, "users", userId, "computers", hostId, "logs")
        const dayKeys = Object.keys(logsPerDay)
        const q = query(pointer, where("date", "in", dayKeys))
        console.log('Getting logs for days:', dayKeys)
        const querySnapshot = await getDocs(q)

        querySnapshot.forEach(doc => {
            if (doc.exists()) {
                const existingDoc = doc.data()
                logsPerDay[existingDoc.date].forEach(updatedLog => {
                    const existingLog = existingDoc.logs.find(log => log.startTime.seconds === updatedLog.startTime.seconds)
                    if (existingLog) {
                        existingLog.logs = updatedLog.logs
                    } else {
                        existingDoc.logs.push(updatedLog)
                    }
                })
                console.log('Updated doc [' + existingDoc.date + '] data: ', existingDoc)
                batch.set(doc.ref, existingDoc)
                const i = dayKeys.indexOf(existingDoc.date)
                if (i > -1) {
                    dayKeys.splice(i, 1)
                }
            }
        })

        dayKeys.forEach(dayKey => {
            const newDoc = { date: dayKey, logs: logsPerDay[dayKey] }
            console.log('Created new doc [' + dayKey + '] data: ', newDoc)
            const docRef = doc(pointer, dayKey)
            batch.set(docRef, newDoc)
        })

        console.log('Committing changes...')
        await batch.commit() // Commit all changes in batch
        return true
    } catch (error) {
        console.log('Error syncing logs:', error)
        return false
    }
}

async function getLogsFromFirestore({ userId, hostId, startDate, endDate }) {
    try {
        const localStorageLogs = getLocalStorageLogs({ userId, hostId })
        if (localStorageLogs && localStorageLogs.length > 0) {
            console.log('Cached logs:', localStorageLogs)
            return localStorageLogs
        }

        console.log('[Firestore] Getting logs for user:', userId, 'computer:', hostId)

        const pointer = collection(db, "users", userId, "computers", hostId, "logs")
        const logs = []

        const q = query(pointer, where("date", ">=", startDate), where("date", "<=", endDate))
        console.log('Getting logs from:', startDate, 'to', endDate)
        const querySnapshot = await getDocs(q)
        querySnapshot.forEach(doc => {
            if (doc.exists()) logs.push(...doc.data().logs)
        })

        console.log('Firestore logs:', logs)
        return logs
    } catch (error) {
        console.log('Error getting logs:', error)
        return []
    }
}

async function insertMessage(userId, message) {
    try {
        const newMessage = {
            message,
            date: new Date().getTime()
        }

        console.log('[FIREBASE] Inserting message:', message)
        const pointer = doc(db, "feedback", userId)
        var messagesDoc = await getDoc(pointer)

        if (messagesDoc.exists()) {
            messagesDoc = messagesDoc.data()
            messagesDoc.messages = messagesDoc.messages ? messagesDoc.messages : []
        } else {
            messagesDoc = { messages: [] }
        }

        messagesDoc.messages.push(newMessage)

        await setDoc(pointer, messagesDoc)

        return { success: true, msg: 'Message sent!' }
    } catch (error) {
        console.log('Error inserting message:', error)
        return { success: false, msg: 'Something went wrong. Please try again later.' }
    }
}

async function updateLogs(userId, hostId, updates) {
    try {
        console.log('[Firestore] Updating logs in batch...');

        const localStorageLogs = getLocalStorageLogs({ userId, hostId })

        const batch = writeBatch(db);
        const collectionRef = collection(db, 'users', userId, 'computers', hostId, 'logs');

        const updatesPerDay = {}
        updates.forEach(update => {
            const docId = getDayKeyFromDate(convertTimestampToDate({ seconds: update.logId, nanoseconds: 0 }));
            if (!updatesPerDay[docId]) updatesPerDay[docId] = []
            updatesPerDay[docId].push(update)

            const localLogToUpdate = localStorageLogs.find(log => log.startTime.seconds === update.logId);

            switch (update.type) {
                case 'changeTitle':
                    if (localLogToUpdate) {
                        localLogToUpdate.title = update.value;
                    }
                    break;
                case 'addTag':
                    const existingTags = localLogToUpdate?.tags || [];
                    const existingTag = existingTags.find(tag => tag.category === update.value.category);
                    if (existingTag) {
                        existingTag.value = update.value.value;
                    } else {
                        existingTags.push(update.value);
                    }
                    localLogToUpdate.tags = existingTags;
                    break;
                case 'removeTag':
                    const tagIndex = localLogToUpdate?.tags.findIndex(tag => tag.category === update.value);
                    if (tagIndex > -1) {
                        localLogToUpdate.tags.splice(tagIndex, 1);
                    }
                    break;
                case 'deleteLog':
                    const logIndex = localStorageLogs.findIndex(log => log.startTime.seconds === update.logId);
                    if (logIndex > -1) {
                        localStorageLogs.splice(logIndex, 1);
                    }
                    break;
                default:
                    break;
            }
        })

        setLocalStorageLogs({ userId, hostId, logs: localStorageLogs })

        console.log('Updates per day:', updatesPerDay);

        for (let docId in updatesPerDay) {
            const logsToUpdate = updatesPerDay[docId];
            const docRef = doc(collectionRef, docId);
            const docSnap = await getDoc(docRef);
            if (!docSnap.exists()) {
                console.log('Document does not exist:', docId);
                continue;
            }

            const docData = docSnap.data();
            logsToUpdate.forEach(update => {
                const logToUpdate = docData.logs.find(log => log.startTime.seconds === update.logId);

                switch (update.type) {
                    case 'changeTitle':
                        if (logToUpdate) {
                            logToUpdate.title = update.value;
                        }
                        break;
                    case 'addTag':
                        const existingTags = logToUpdate?.tags || [];
                        const existingTag = existingTags.find(tag => tag.category === update.value.category);
                        if (existingTag) {
                            existingTag.value = update.value.value;
                        } else {
                            existingTags.push(update.value);
                        }
                        logToUpdate.tags = existingTags;
                        break;
                    case 'removeTag':
                        const tagIndex = logToUpdate?.tags.findIndex(tag => tag.category === update.value);
                        if (tagIndex > -1) {
                            logToUpdate.tags.splice(tagIndex, 1);
                        }
                        break;
                    case 'deleteLog':
                        const logIndex = docData.logs.findIndex(log => log.startTime.seconds === update.logId);
                        if (logIndex > -1) {
                            docData.logs.splice(logIndex, 1);
                        }
                        break;
                    default:
                        break;
                }
            });

            batch.set(docRef, docData);
        }

        await batch.commit();

        console.log('Logs updated successfully!');
        return true;
    } catch (error) {
        console.log('Error updating logs:', error);
        return false;
    }
}

export {
    getUserDocument,
    modifyUserDocument,
    insertMessage,
    getUserComputerDocument,
    getUserComputerDocuments,
    createUserComputerDocument,
    updateUserComputerDocument,
    getUserComputerLogs,
    updateLogs
}