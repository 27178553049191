import React from 'react'
import { CheckBoxIcon, MinusIcon } from './Icons'

const SelectButton = ({ check, isChecked, showClear }) => {
    return (
        <button
            className={'selectButton' + (isChecked || showClear ? ' selectButtonChecked' : '')}
            onClick={check}
            onFocus={(e) => e.target.blur()}
        >
            {isChecked && <CheckBoxIcon />}
            {showClear && !isChecked && <MinusIcon />}
        </button>
    )
}

export default SelectButton