import { initializeApp } from "firebase/app"
import { GoogleAuthProvider, getAuth } from "firebase/auth"
import { getFirestore } from "firebase/firestore"

// App configuration
const app = initializeApp({
    apiKey: "AIzaSyCZzbaDIJAZjWb9lOAlVx6OTsY40bykB-Q",
    authDomain: "time-logify.firebaseapp.com",
    projectId: "time-logify",
    storageBucket: "time-logify.appspot.com",
    messagingSenderId: "419698014682",
    appId: "1:419698014682:web:fb93f266f678f998a668e3",
    measurementId: "G-D6Q1ZHCN37"
})

// Firebase services
export const auth = getAuth(app)
export const googleProvider = new GoogleAuthProvider()
export const db = getFirestore()
