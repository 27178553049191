export function remToPixels(rem) {
    // Create a temporary element
    const tempElement = document.createElement('div');

    // Set its size to 1rem
    tempElement.style.fontSize = '1rem';

    // Append it to the body to compute the value
    document.body.appendChild(tempElement);

    // Get the computed font-size (in pixels) for 1rem
    const remInPixels = parseFloat(window.getComputedStyle(tempElement).fontSize);

    // Remove the temporary element from the DOM
    document.body.removeChild(tempElement);

    // Return the equivalent pixel value for the given rem
    return rem * remInPixels;
}