import React from 'react'

function LoadingText() {
    return (
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
            <div className='loadingSpinner' />
            <div className='loadingText'>Loading...</div>
        </div>
    )
}

export default LoadingText