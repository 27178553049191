import React, { useEffect, useState } from 'react'
import { convertTimestampToDate } from '../mgt/timeMgt'
import { getDurationString } from '../mgt/timeMgt'
import { useGlobals } from '../mgt/GlobalsProvider'
import { SortAscIcon } from './Icons'

const TimelineLogsTable = () => {
    const [timeline, setTimeline] = useState([])
    const { filteredLogs: logs, currentSort, setCurrentSort, updateSorting } = useGlobals()

    useEffect(() => {
        const sort = { ...currentSort, column: 1 }
        updateSorting(sort)
    }, [])


    useEffect(() => {
        if (!logs?.length) {
            setTimeline([])
            return
        }

        createTimeline()
    }, [logs])

    function createTimeline() {
        const timeline = []
        function getLastTitle() {
            return timeline[timeline.length - 1].title
        }
        function addTimelinePoint(log) {
            if (!log.title) return
            timeline.push({
                time: log.timestamp,
                title: log.title,
                total: 1
            })
        }

        logs.forEach(logDoc => {
            logDoc.logs.reverse()
            logDoc.logs.forEach(log => {
                if (!timeline.length) {
                    addTimelinePoint(log)
                    return
                }

                if (getLastTitle() !== log.title) {
                    addTimelinePoint(log)
                } else {
                    timeline[timeline.length - 1].total++
                }
            })
        })
        setTimeline(timeline)
    }

    function handleChangeSort(column) {
        let newSort = {}
        if (currentSort.column === column) {
            newSort = { column, desc: !currentSort.desc }
        } else {
            newSort = { column, desc: currentSort.desc }
        }

        setCurrentSort(newSort)

        updateSorting(newSort)
    }

    return <>
        <div className='summarizedLogsContainer'>
            <div className='summarizedLogsHeader'>
                <div style={{ width: '100%' }} className='summarizedLogsHeaderTitle'>
                    <div className='detailedLogsHeaderTitleContent' onClick={() => handleChangeSort(1)} >
                        Active windows
                        <SortAscIcon flipped={currentSort.desc} color={'var(--text-color-subtler)'} size={16} />
                    </div>
                </div>
            </div>
            <div className='summarizedLogsBody'>
                {timeline.map((point, index) => {

                    const time = convertTimestampToDate(point.time).toLocaleTimeString('nl-BE').slice(0, -3)
                    const title = point.title

                    return <div key={index} className='summarizedLogsBodyRow'>
                        <div style={{ width: '100%' }} className='summarizedLogsBodyTitle'>
                            <span style={{ color: 'var(--text-color-subtler)' }}>By </span>
                            {time}
                            <span style={{ color: 'var(--text-color-subtler)' }}>,  you spent </span>
                            {getDurationString(point.total, true)}
                            <span style={{ color: 'var(--text-color-subtler)' }}> on </span>
                            {title}
                            <span style={{ color: 'var(--text-color-subtler)' }}>.</span></div>
                    </div>
                })}
            </div>
        </div>
    </>
}

export default TimelineLogsTable
