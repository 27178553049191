import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './pages/App.jsx';

const timeLogify = ReactDOM.createRoot(document.getElementById('TimeLogify'));
timeLogify.render(
  <App />
);



