import React from 'react'
import { useNavigate } from 'react-router-dom'
import ModularButton from '../components/ModularButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../mgt/firebase';
import HeaderBar from '../components/HeaderBar';
import VerticalSpacer from '../components/VerticalSpacer';

function Home() {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth)

    function goToLogin() {
        navigate('/login');
    }

    function goToRegister() {
        navigate('/register');
    }

    if (loading) return <>
        <HeaderBar />
        <div className='loadingText'>Loading...</div>
        <div className='loadingSpinner' />
    </>

    return <>
        <HeaderBar />

        <div className='homeSection'>
            <div className='homeSubtitle'>👋 Say goodbye to losing time tracking your hours</div>
            <div className='homeSpacer' />
            <div className='homeSubtext'>TimeLogify automatically tracks your time through all programs, so you can focus on what matters most. Simply create an account and download TimeLogify to start forgetting about time registration.</div>
        </div>

        <div className='verticalSpacerLarge' />
        <VerticalSpacer />

        {user ?
            <ModularButton
                title='Go to your dashboard'
                onClick={() => navigate('/dashboard')}
                border={true}
                wide={true}
            />
            : <>
                <div style={{ gap: '1rem', display: 'flex' }}>
                    <ModularButton
                        title='Sign up'
                        onClick={goToRegister}
                        border={false}
                        wide={true}
                    />
                    <ModularButton
                        title='Log in'
                        onClick={goToLogin}
                        border={true}
                        wide={true}
                    />
                </div>
                <VerticalSpacer />
                <VerticalSpacer />
                <div className='homeSubtext'>Log in or create an account to start using TimeLogify for free!</div>
            </>
        }

        <div className='verticalSpacerLarge' />
        <div className='verticalSpacerLarge' />

    </>
}

export default Home