import React, { useEffect, useState } from 'react'
import { handleRegisterWithEmail, handleSignInWithPopupGoogle } from '../mgt/authMgt'
import { useLocation, useNavigate } from 'react-router-dom'
import { RevealIcon, GoogleIcon } from '../components/Icons'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import ModularButton from '../components/ModularButton'
import HeaderBar from '../components/HeaderBar';
import VerticalSpacer from '../components/VerticalSpacer'

const Register = () => {
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect') || false;
    const [displayNameInput, setDisplayNameInput] = useState('')
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [nameError, setNameError] = useState('')
    const [nameErrText, setNameErrText] = useState('')
    const [emailError, setEmailError] = useState('')
    const [emailErrText, setEmailErrText] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordErrText, setPasswordErrText] = useState('')

    useEffect(() => {
        if (loading) return
        if (redirect && user) {
            const newRedirect = redirect.split('?')[0]
            navigate(newRedirect)
        }

        if (user) {
            navigate('/dashboard')
        }
    }, [loading, user])

    const tryLoginWithGoogle = async () => {
        const userDoc = await handleSignInWithPopupGoogle()
        if (userDoc) {
            handleRegisterSuccess(userDoc.id)
        }
    }

    const tryRegisterWithEmail = async () => {
        if (!checkNameInput()) return
        if (!checkEmailInput(true)) return
        if (!checkPasswordInput(true)) return

        const userDoc = await handleRegisterWithEmail(emailInput, passwordInput, displayNameInput)
        if (userDoc) {
            handleRegisterSuccess(userDoc.id)
        }
    }

    function checkNameInput() {
        if (displayNameInput === '') {
            setNameError('show')
            setNameErrText('Your name cannot be empty')
            return false
        } else {
            setNameError('')
            return true
        }
    }
    useEffect(() => {
        if (displayNameInput !== '') {
            setNameError('')
            setNameErrText('')
        }
    }, [displayNameInput])

    function checkEmailInput(checkEmpty) {
        if (emailInput === '') {
            if (checkEmpty === true) {
                setEmailError('show')
                setEmailErrText('Please enter your email')
                return false
            } else {
                setEmailError('')
                return true
            }
        } else {
            const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailRegex.test(emailInput)) {
                setEmailError('')
                return true
            } else {
                setEmailError('show')
                setEmailErrText('Please enter a valid email address')
                return false
            }
        }
    }
    useEffect(() => {
        if (emailInput !== '') {
            setEmailError('')
            setEmailErrText('')
        }
    }, [emailInput])

    useEffect(() => {
        if (passwordInput.length >= 6) {
            setPasswordError('')
            setPasswordErrText('')
        }
    }, [passwordInput])

    function checkPasswordInput(checkEmpty) {
        if (passwordInput === '') {
            if (checkEmpty === true) {
                setPasswordError('show')
                setPasswordErrText('Please enter your password')
                return false
            } else {
                setPasswordError('')
                return true
            }
        }

        if (passwordInput.length < 6) {
            setPasswordError('show')
            setPasswordErrText('Your password must be at least 6 characters long')
            return false
        }

        return true
    }

    function handleRegisterSuccess(userId) {
        if (redirect)
            navigate(redirect)
        else
            navigate('/dashboard')
    }

    function switchToLogin() {
        if (redirect)
            navigate(`/login?redirect=${redirect}`)
        else
            navigate('/login')
    }

    return <>
        <HeaderBar />

        <div className='loginSection'>
            <div className='homeSubtitle'>Create a new account</div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='homeSpacer' />
                <span
                    tabIndex={0}
                    className='loginRegisterSwitchText'
                    onClick={switchToLogin}>
                    {'Log into your account >'}
                </span>
            </div>

            <VerticalSpacer />


            <div className='registerInputLbl'>Name</div>
            <div className='registerInputWrapper'>
                <input
                    className='registerInput'
                    placeholder='Enter your name'
                    value={displayNameInput}
                    onChange={(e) => setDisplayNameInput(e.target.value)}
                    autoFocus
                />
                <div className={`inputErrorLbl ${nameError}`}>{nameErrText}</div>
            </div>


            <div className='registerInputLbl'>Email</div>
            <div className='registerInputWrapper'>
                <input
                    placeholder='Enter your email'
                    className='registerInput'
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={checkEmailInput}
                />
                <div className={`inputErrorLbl ${emailError}`}>{emailErrText}</div>
            </div>

            <div className='registerInputLbl'>Password</div>
            <div className='registerInputWrapper'>
                <input
                    placeholder='Enter your password'
                    className='registerInput'
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    onBlur={checkPasswordInput}
                    type={passwordVisible ? 'text' : 'password'}
                    onKeyDown={(e) => { if (e.key === 'Enter') tryRegisterWithEmail() }}
                />
                <div className='showPasswordIcon' onClick={() => setPasswordVisible(!passwordVisible)}>
                    <RevealIcon size={30} transparent={true} grey={!passwordVisible} />
                </div>
            </div>
            <div className={`inputErrorLbl ${passwordError}`}>{passwordErrText}</div>

            <VerticalSpacer />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ModularButton
                    title='Sign up'
                    onClick={tryRegisterWithEmail}
                    border={false}
                    wide={true}
                />
                <VerticalSpacer />
                <ModularButton
                    title='Continue with Google'
                    onClick={tryLoginWithGoogle}
                    border={true}
                    wide={true}
                    iconEnd={<GoogleIcon size={20} />}
                />
            </div>

            <VerticalSpacer />
            <VerticalSpacer />
            <VerticalSpacer />

            <ModularButton
                title='Go back'
                onClick={() => navigate('/')}
                border={false}
                wide={true}
            />

        </div>
    </>
}

export default Register
