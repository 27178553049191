import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { convertTimestampToDate, convertToLocalDate } from './timeMgt';
import { customInactiveTxt } from './constants';

const exportToExcel = async (data, selectedDate, userTags) => {

    let compressedLogs = compressLogsForExport(data);

    // FileName = 'TimeLogify-export-YYYY-MM-DD.xlsx' (local time)
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1); // Add 1 day to get the correct date
    const dateStr = newDate.toISOString().split('T')[0].replace('_', '-');
    const fileName = `TimeLogify-export-${dateStr}`;

    // Create a new workbook and add a worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet(dateStr);

    const tagColums = userTags.map(tag => ({ header: tag.category, key: tag.category }));

    // Define columns for the worksheet, excluding "logs"
    const columns = [
        ...tagColums, // Add columns for user tags
        { header: 'Start Time', key: 'startTime' },
        { header: 'End Time', key: 'endTime' },
        { header: ' ', key: '_empty' }, // Add a column for the empty tag category
        { header: 'Total', key: 'total' },
        { header: 'Automatically logged title', key: 'title' },
    ];

    compressedLogs = compressedLogs.map(row => {
        const newRow = { ...row };
        newRow.total = (newRow.endTime.seconds - newRow.startTime.seconds) / (60 * 60)
        return newRow;
    });

    worksheet.columns = columns;

    // New: include tags (one column for each category, with the tag value as the cell value)
    const rowsWithoutLogs = compressedLogs.map(row => {
        const newRow = {};
        Object.entries(row).forEach(([key, value]) => {
            // Convert "start time" if it's in the {"seconds", "nanoseconds"} format
            if ((key === 'startTime' || key === 'endTime') && value && typeof value === 'object' && 'seconds' in value) {
                newRow[key] = convertToLocalDate(convertTimestampToDate(value));
            } else if (key !== 'logs') {
                newRow[key] = value;
            }
        });

        // Add tag values to the row
        userTags.forEach(tag => {
            const tagValue = row?.tags?.find(t => t.category === tag.category)?.value || '';
            if (tagValue) {
                newRow[tag.category] = tagValue
            } else {
                newRow[tag.category] = '';
            }
        });
        const emptyTagValue = row?.tags?.find(t => t.category === '')?.value || '';
        if (emptyTagValue) newRow['_empty'] = emptyTagValue;

        return newRow;
    });

    // Add rows to worksheet
    rowsWithoutLogs.forEach(row => {
        worksheet.addRow(row);
    });

    // Add a table to the worksheet with alternating row colors
    worksheet.addTable({
        name: 'Table1',
        ref: 'A1',
        headerRow: true,
        style: {
            theme: 'TableStyleMedium2',
            showRowStripes: true,
        },
        columns: columns.map(col => ({ name: col.header })),
        rows: rowsWithoutLogs.map(row => {
            // Ensure the order of row values matches the columns
            return columns.map(col => row[col.key] || '');
        }),
    });

    // Format date columns
    worksheet.columns.forEach(col => {
        if (col.key === 'startTime' || col.key === 'endTime') {
            col.numFmt = 'hh:mm'; // Adjust format as needed
        }
    });

    // Save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
};

function compressLogsForExport(data) {
    const resultLogs = [];

    const sortedData = [...data];
    sortedData.sort((a, b) => a.startTime.seconds - b.startTime.seconds);

    for (let i = 0; i < sortedData.length; i++) {
        const currLog = { ...sortedData[i] }; // Create a copy of currLog
        if (i === 0) {
            currLog.endTime = add15MinutesToTime(currLog.startTime);
            resultLogs.push(cleanLogLine(currLog));
        } else {
            const prevLog = resultLogs[resultLogs.length - 1];
            if (tagsAreEqual(currLog, prevLog)) {
                prevLog.endTime = add15MinutesToTime(currLog.startTime);
            } else {
                currLog.endTime = add15MinutesToTime(currLog.startTime);
                resultLogs.push(cleanLogLine(currLog));
            }
        }
    }

    return resultLogs;
}

function add15MinutesToTime(timeStamp) { // timestamp = { seconds: number, nanoseconds: number }
    const newTimeStamp = { ...timeStamp };
    newTimeStamp.seconds += 15 * 60;
    return newTimeStamp;
}

function cleanLogLine(logLine) {
    return {
        title: logLine.title,
        startTime: logLine.startTime,
        endTime: logLine.endTime,
        tags: logLine.tags,
    };
}

function tagsAreEqual(log1, log2) {
    const tags1 = log1.tags || [];
    const tags2 = log2.tags || [];
    if (tags1.length !== tags2.length) return false;

    if (tags1.length === 0 && tags2.length === 0) {
        return (log1.title === log2.title);
    }

    const allMatch = tags1.every(tag1 => {
        const tag2 = tags2.find(t => t.category === tag1.category);
        return tag2 && tag2.value === tag1.value;
    });

    return allMatch;
}

function compressLogsForExportForVCO(data) {
    return compressLogsForExport(data)
        .filter(log => log.title !== customInactiveTxt)
        .map(log => {
            return {
                date: convertTimestampToDate(log.startTime).toLocaleDateString('nl-BE'),
                project: log?.tags?.find(t => t.category === 'Project')?.value || '',
                actionPoint: log?.tags?.find(t => t.category === 'Action Point')?.value || '',
                location: log?.tags?.find(t => t.category === 'Location')?.value || 'Office',
                workType: log?.tags?.find(t => t.category === 'Work Type')?.value || 'CON',
                startTime: convertTimestampToDate(log.startTime).toLocaleTimeString('nl-BE', { hour: '2-digit', minute: '2-digit' }),
                endTime: convertTimestampToDate(log.endTime).toLocaleTimeString('nl-BE', { hour: '2-digit', minute: '2-digit' }),
                empty: '',
                user: 'Vincent G',
                title: log.title.length > 50 ? log.title.substring(0, 50) : log.title,
            };
        });
}

async function exportForVCO(data, selectedDate, userTags, vcoUserName) {
    if (vcoUserName === '') {
        return;
    }

    // Compress lines
    console.log('input:', data)
    let compressedLogs = compressLogsForExportForVCO(data);
    console.log('compressed:', compressedLogs)

    // Create a new Excel workbook
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Logs');

    // Add an empty first row
    worksheet.addRow([]);

    // Add rows without headers
    compressedLogs.forEach((log) => {
        worksheet.addRow([
            log.date,
            log.project,
            log.actionPoint,
            log.location,
            log.workType,
            log.startTime,
            log.endTime,
            log.empty,
            vcoUserName,
            log.title,
        ]);
    })

    // FileName = 'TimeLogify-export-YYYY-MM-DD.xlsx' (local time)
    const newDate = new Date(selectedDate);
    newDate.setDate(selectedDate.getDate() + 1); // Add 1 day to get the correct date
    const dateStr = newDate.toISOString().split('T')[0].replace('_', '-');
    const fileName = `TimeLogify-export-${dateStr}`;

    // Save the Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `${fileName}.xlsx`);
}

export {
    exportToExcel,
    exportForVCO,
}
