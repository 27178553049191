import React, { createContext, useContext, useState, useEffect } from 'react'
import { updateLogs } from './firestoreAPI'
import { useGlobals } from './GlobalsProvider'
const QueueContext = createContext()

/**
* @example
* const { addOperation, addNotification } = useQueue();
*/
export const useQueue = () => {
    return useContext(QueueContext)
}

export const QueueProvider = ({ children }) => {
    const [queue, setQueue] = useState([])
    const [notifications, setNotifications] = useState([])
    const { selectedHostId, setSelectedHostId, userDoc, setUserDoc } = useGlobals()

    function addOperation(operation) {
        console.log('Action queued:', operation)
        setQueue((prev) => [...prev, operation])
    }

    function addNotification(notification) {
        console.log('addNotification()', notification)
        setNotifications((prev) => [...prev, notification])
    }

    async function processQueue() {
        let currQueue = []
        setQueue((prevQueue) => {
            currQueue = [...prevQueue]
            return [];
        });

        // Use a small delay to ensure setQueue has completed before continuing
        await new Promise((resolve) => setTimeout(resolve, 0));

        if (currQueue.length === 0) return
        await updateLogs(userDoc.id, selectedHostId, currQueue)
    }

    useEffect(() => {
        if (!userDoc || !selectedHostId) return

        const interval = setInterval(processQueue, 5000);
        return () => clearInterval(interval);
    }, [userDoc, selectedHostId]);

    return (
        <QueueContext.Provider value={{ addOperation, addNotification }}>
            {children}
        </QueueContext.Provider>
    )
}
