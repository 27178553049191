// Imports
import React, { useEffect, useState, useRef, createRef } from 'react'
import { useQueue } from '../mgt/QueueProvider'
import { useGlobals } from '../mgt/GlobalsProvider'
import { customInactiveTxt, tagBackgroundColor, tagBorderColor, tagColor } from '../mgt/constants'

// Functions
import { remToPixels } from '../mgt/layoutMgt'
import { convertTimestampToDate } from '../mgt/timeMgt'
import { CheckmarkIcon, EditPencilIcon, JiraIcon, ListIcon, MenuDotsIcon, PlusCircleIcon, SaveIcon, SortAscIcon, TagIcon } from './Icons'

// Components
import ModularButton from './ModularButton'
import SelectButton from './SelectButton'
import VerticalSpacer from './VerticalSpacer'

const oneRemToPixels = remToPixels(1)

const DetailedLogsTable = () => {
    // Variables
    const {
        filteredLogs: logs,
        setFilteredLogs: setLogs,
        currentSort, setCurrentSort, updateSorting,
        selectedLines, setSelectedLines
    } = useGlobals()
    const [hoveredLine, setHoveredLine] = useState(null)
    const { addOperation, addNotification } = useQueue();
    const [lineInEditMode, setLineInEditMode] = useState(null)
    const lineLogEntriesPopupRefs = useRef([])
    const lineAddTagPopupRefs = useRef([])
    const selectionEditNamePopupRef = useRef(null)
    const selectionAddTagPopupRef = useRef(null)
    const [editedTitle, setEditedTitle] = useState('')
    const [newTagValue, setNewTagValue] = useState('')
    const [newTag, setNewTag] = useState({ category: '', value: '' })
    const [userTags, setUserTags] = useState([])
    const { userDoc } = useGlobals()
    const [tagAutoCompleteText, setTagAutoCompleteText] = useState('')
    const [tagAutoCompleteValue, setTagAutoCompleteValue] = useState('')
    const [addTagCategoryHovering, setAddTagCategoryHovering] = useState(null)

    useEffect(() => {
        if (!userDoc) return

        console.log('User tags:', userDoc.settings.tags)

        setUserTags([...userDoc.settings.tags])
    }, [userDoc])

    useEffect(() => {
        function acceptAutoComplete(e) {
            if (e.key === 'Tab') {
                e.preventDefault()
                setNewTagValue(tagAutoCompleteValue)
            }
        }

        if (tagAutoCompleteText !== '') {
            document.addEventListener('keydown', acceptAutoComplete)
        } else {
            document.removeEventListener('keydown', acceptAutoComplete)
        }

        return () => document.removeEventListener('keydown', acceptAutoComplete)
    }, [tagAutoCompleteText])

    useEffect(() => {
        if (tagAutoCompleteText === '') {
            setTagAutoCompleteValue('')
        }
    }, [tagAutoCompleteText])

    useEffect(() => {
        if (newTagValue === '') {
            setTagAutoCompleteText('')
        } else {
            const conditionValues = userDoc.settings.rules.filter(rule => rule.source === 'newTag' && rule.conditionValue.length <= 15).map(rule => rule.conditionValue)
            const autoCompleteValues = conditionValues.filter(value => newTagValue !== '' && value.toLowerCase().startsWith(newTagValue.toLowerCase()))
            const autoCompleteText = autoCompleteValues.length === 0 ? '' : newTagValue + autoCompleteValues[0]?.slice(newTagValue.length) || ''
            setTagAutoCompleteText(autoCompleteText)
            setTagAutoCompleteValue(autoCompleteValues[0] || '')
        }

        const newTag = { category: '', value: newTagValue }

        const setCategoryRules = userDoc.settings.rules.filter(rule => rule.source === 'newTag' && rule.action === 'setTagCategory')
        for (let rule of setCategoryRules) {
            const inputValue = newTagValue.toLowerCase()
            const conditionValue = rule.conditionValue.toLowerCase()

            if ((rule.condition === 'equals' && inputValue === conditionValue)
                || (rule.condition === 'contains' && inputValue.includes(conditionValue))
                || (rule.condition === 'startsWith' && inputValue.startsWith(conditionValue))
                || (rule.condition === 'endsWith' && inputValue.endsWith(conditionValue))) {
                newTag.category = rule.actionValue
            }
        }

        setNewTag(newTag)
    }, [newTagValue])

    // UseEffects
    useEffect(() => {
        updateSorting(currentSort)
    }, [])

    useEffect(() => {
        if (!selectedLines.length) return

        const escapeListener = document.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                setSelectedLines([])
            }
        })

        return () => document.removeEventListener('keydown', escapeListener)
    }, [selectedLines])

    useEffect(() => {
        lineLogEntriesPopupRefs.current = logs.map((_, i) => lineLogEntriesPopupRefs.current[i] || createRef())
        lineAddTagPopupRefs.current = logs.map((_, i) => lineAddTagPopupRefs.current[i] || createRef())
    }, [logs])

    // Functions
    function handleChangeTitle(i, index) {
        addOperation({ type: 'changeTitle', logId: logs[index].startTime.seconds, value: logs[index].logs[i].title })

        const updatedLogs = [...logs]
        updatedLogs[index].title = logs[index].logs[i].title

        setLogs(updatedLogs)
    }

    function handleChangeCustomTitle(index, log) {
        if ((editedTitle !== '') && (editedTitle !== log.title)) {
            addOperation({
                type: 'changeTitle',
                logId: log.startTime.seconds,
                value: editedTitle
            })

            const updatedLogs = [...logs]
            updatedLogs[index].title = editedTitle
            setLogs(updatedLogs)
        }
        setLineInEditMode(null)
    }

    function handleAddTag(index, log) {
        addOperation({
            type: 'addTag',
            logId: log.startTime.seconds,
            value: newTag
        })

        const updatedLogs = [...logs]
        const existingTags = updatedLogs[index].tags || []
        const existingTag = existingTags.find(tag => tag.category === newTag.category)
        if (existingTag) {
            existingTag.value = newTag.value
        } else {
            updatedLogs[index].tags = [...existingTags, newTag]
        }

        const addExtraTagRules = userDoc.settings.rules.filter(rule => rule.source === 'newTag' && rule.action === 'addTag')
        for (let rule of addExtraTagRules) {
            const inputValue = newTag.value.toLowerCase()
            const conditionValue = rule.conditionValue.toLowerCase()

            if ((rule.condition === 'equals' && inputValue === conditionValue)
                || (rule.condition === 'contains' && inputValue.includes(conditionValue))
                || (rule.condition === 'startsWith' && inputValue.startsWith(conditionValue))
                || (rule.condition === 'endsWith' && inputValue.endsWith(conditionValue))) {

                const existingExtraTag = updatedLogs[index].tags.find(tag => tag.category === rule.actionValue)
                if (existingExtraTag) {
                    existingExtraTag.value = rule.addTagValue
                } else {
                    updatedLogs[index].tags = [...updatedLogs[index].tags, { category: rule.actionValue, value: rule.addTagValue }]
                }

                addOperation({
                    type: 'addTag',
                    logId: log.startTime.seconds,
                    value: { category: rule.actionValue, value: rule.addTagValue }
                })
            }
        }

        setLogs(updatedLogs)
        setNewTag({ category: '', value: '' })
        setAddTagCategoryHovering(null)
        setNewTagValue('')
        setTagAutoCompleteText('')
    }

    function handleRemoveTag(index, log, category) {
        addOperation({ type: 'removeTag', logId: log.startTime.seconds, value: category })

        const updatedLogs = [...logs]
        updatedLogs[index].tags = updatedLogs[index].tags.filter(tag => tag.category !== category)

        setLogs(updatedLogs)
    }

    function handleDeleteSelectedLogs() {
        for (let i of selectedLines) {
            addOperation({ type: 'deleteLog', logId: logs[i].startTime.seconds })
        }

        const updatedLogs = logs.filter((_, i) => !selectedLines.includes(i))
        setLogs(updatedLogs)
        setSelectedLines([])
    }

    function handleChangeSort(column) {
        let newSort = {}
        if (currentSort.column === column) {
            newSort = { column, desc: !currentSort.desc }
        } else {
            newSort = { column, desc: currentSort.desc }
        }

        setCurrentSort(newSort)

        updateSorting(newSort)
    }

    function handleSelectLine(index, event) {
        if (index === 'all') {
            if (selectedLines.length === logs.length) {
                setSelectedLines([])
            } else {
                setSelectedLines(logs.map((_, i) => i))
            }
            return
        }

        if (event.shiftKey && selectedLines.length > 0) {
            const start = Math.min(selectedLines[selectedLines.length - 1], index)
            const end = Math.max(selectedLines[selectedLines.length - 1], index)
            const newSelections = Array.from({ length: end - start + 1 }, (_, i) => i + start)
            setSelectedLines([...new Set([...selectedLines, ...newSelections])])
            return
        }

        if (selectedLines.includes(index)) {
            setSelectedLines(selectedLines.filter(i => i !== index))
        } else {
            setSelectedLines([...selectedLines, index])
        }
    }

    return <>
        <div className='detailedLogsContainer'>

            {/* Header */}
            <div className='detailedLogsHeader'>

                {/* Select all button*/}
                <SelectButton
                    check={() => {
                        if (selectedLines.length > 0 && selectedLines.length !== logs.length)
                            setSelectedLines([])
                        else
                            handleSelectLine('all')
                    }}
                    isChecked={logs.length !== 0 && selectedLines.length === logs.length}
                    showClear={selectedLines.length > 0}
                />

                {!selectedLines.length ?
                    <>
                        {/* Column labels */}
                        <div className='detailedLogsHeaderTitle'>
                            <div className='detailedLogsHeaderTitleContent' onClick={() => handleChangeSort(0)}>
                                Date
                                {currentSort.column === 0 &&
                                    <SortAscIcon flipped={currentSort.desc} color={'var(--text-color-subtler)'} size={oneRemToPixels} />
                                }
                            </div>
                        </div>
                        <div className='detailedLogsHeaderTitle'>
                            <div className='detailedLogsHeaderTitleContent' onClick={() => handleChangeSort(1)}>
                                Start time
                                {currentSort.column === 1 &&
                                    <SortAscIcon flipped={currentSort.desc} color={'var(--text-color-subtler)'} size={oneRemToPixels} />
                                }
                            </div>
                        </div>
                        <div className='detailedLogsHeaderTitle'>
                            <div className='detailedLogsHeaderTitleContent' onClick={() => handleChangeSort(2)}>
                                Active window
                                {currentSort.column === 2 &&
                                    <SortAscIcon flipped={currentSort.desc} color={'var(--text-color-subtler)'} size={oneRemToPixels} />
                                }
                            </div>
                        </div>
                    </>
                    :
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', gap: '0.5rem', justifyContent: 'space-between' }}>
                        {/* Selection info */}
                        <div className='detailedLogsHeaderForSelections'>
                            <div className='detailedLogsHeaderSelectionText'>{selectedLines.length} selected</div>
                            <div className='detailedLogsHeaderSelectAllText' onClick={() => handleSelectLine('all')}>
                                {selectedLines.length === logs.length ? 'Undo' : 'Select all logs from today'}
                            </div>
                        </div>

                        {/* Bulk actions */}
                        <div className='detailedLogsHeaderForSelectionsButtons'>
                            <ModularButton
                                title='Change title'
                                ref={selectionEditNamePopupRef}
                                iconEnd={<EditPencilIcon size={remToPixels(1.2)} />}
                                onClick={() => { }}
                                style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem' }}
                                popupAlignmentVertical='bottom'
                                popupAlignmentHorizontal='right'
                                popupContent={
                                    <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem', display: 'flex', gap: '0.2rem' }}>
                                        <input
                                            type='text'
                                            // defaultValue={logs[selectedLines[0]].title}
                                            onChange={(e) => setEditedTitle(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter' || e.key === 'Tab') {
                                                    for (let i of selectedLines) {
                                                        handleChangeCustomTitle(i, logs[i])
                                                    }
                                                    selectionEditNamePopupRef.current.closePopup()
                                                }
                                            }}
                                            autoFocus
                                            className='detailedLogsBodyRowInput'
                                            style={{ width: 'auto', textAlign: 'left', padding: '0.2rem 0.5rem' }}
                                        />
                                        <ModularButton
                                            iconEnd={<SaveIcon size={remToPixels(1.2)} />}
                                            onClick={() => {
                                                for (let i of selectedLines) {
                                                    handleChangeCustomTitle(i, logs[i])
                                                }
                                                selectionEditNamePopupRef.current.closePopup()
                                            }}
                                            style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem' }}
                                        />
                                    </div>
                                }
                            />
                            <ModularButton
                                title='Add tag'
                                iconEnd={<TagIcon size={remToPixels(1.2)} />}
                                style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem' }}
                                popupAlignmentVertical='bottom'
                                popupAlignmentHorizontal='right'
                                onClosePopupFunction={() => setTagAutoCompleteText('')}
                                ref={selectionAddTagPopupRef}
                                popupContent={
                                    <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem' }}>
                                        <input
                                            id='addTagBulkButton'
                                            type='text'
                                            placeholder='Add a tag'
                                            className='detailedLogsBodyRowInput'
                                            style={{ width: 'auto', textAlign: 'left', padding: '0.2rem 0.5rem' }}
                                            autoFocus
                                            value={newTagValue}
                                            onChange={(e) => setNewTagValue(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Enter') {
                                                    for (let i of selectedLines) {
                                                        handleAddTag(i, logs[i])
                                                    }
                                                    selectionAddTagPopupRef.current.closePopup()
                                                }
                                            }}
                                        />
                                        <span className='tagAutoComplete'>{tagAutoCompleteText}{tagAutoCompleteText && tagAutoCompleteValue !== newTagValue && <span className='tagAutoCompleteTab'>Tab</span>}</span>
                                        <VerticalSpacer size={0.3} />
                                        {userTags
                                            .filter(tag => {
                                                return (
                                                    (newTag.category === '') || (newTag.category === tag.category)
                                                )
                                            })
                                            .map((tag, i) => (
                                                <div
                                                    key={i}
                                                    className={'addTagCategory' + (tag.category === newTag.category ? ' addTagCategorySelected' : '')}
                                                    style={{
                                                        backgroundColor: tagBackgroundColor(tag.color),
                                                        color: tagColor(tag.color),
                                                        border: `1px solid ${tagBorderColor(tag.color)}`,
                                                    }}
                                                    onClick={(e) => {
                                                        setNewTag({ ...newTag, category: tag.category })
                                                        const input = document.getElementById('addTagBulkButton')
                                                        input.focus()
                                                        setAddTagCategoryHovering(null)
                                                    }}
                                                    onMouseEnter={() => setAddTagCategoryHovering(i)}
                                                    onMouseLeave={() => setAddTagCategoryHovering(null)}
                                                >
                                                    {tag.category}
                                                    <div
                                                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                        onClick={() => {
                                                            setTimeout(() => setNewTag({ ...newTag, category: '' }), 0)
                                                            const input = document.getElementById('addTagBulkButton')
                                                            input.focus()
                                                            setAddTagCategoryHovering(null)
                                                        }}
                                                    >
                                                        {tag.category === newTag.category ?
                                                            <PlusCircleIcon color={tagColor(tag.color)} rotate={45} size={oneRemToPixels} />
                                                            :
                                                            <CheckmarkIcon style={{ transition: 'all 0.2s', opacity: addTagCategoryHovering === i ? 1 : 0 }} color={tagColor(tag.color)} size={oneRemToPixels} />
                                                        }
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                }
                            />
                            <ModularButton
                                iconEnd={<MenuDotsIcon size={remToPixels(1.2)} />}
                                style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem' }}
                                popupAlignmentVertical='bottom'
                                popupAlignmentHorizontal='right'
                                popupContent={
                                    <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem' }}>
                                        <ModularButton
                                            title={selectedLines.length === 1 ? 'Delete log' : 'Delete selected logs'}
                                            onClick={handleDeleteSelectedLogs}
                                            style={{ color: 'var(--text-color-danger)', fontSize: '0.8rem', padding: '0.3rem 0.5rem' }}
                                        />
                                    </div>
                                }
                            />
                        </div>
                    </div>}
            </div>

            {/* Body */}
            <div className='detailedLogsBody'>
                {logs.map((log, index) => {
                    const dateObj = convertTimestampToDate(log.startTime)
                    const date = dateObj.toLocaleDateString('nl-BE')
                    const time = dateObj.toLocaleTimeString('nl-BE')
                    const EditingLine = lineInEditMode === index
                    const addTagInputKey = `addTagInput-${index}`
                    const allLogsStr = log.logs
                        .sort((a, b) => {
                            const aDate = convertTimestampToDate(a.timestamp)
                            const bDate = convertTimestampToDate(b.timestamp)
                            return bDate - aDate
                        })
                        .map(log => {
                            const lDateObj = convertTimestampToDate(log.timestamp)
                            const lTime = lDateObj.toLocaleTimeString('nl-BE').slice(0, 5)
                            return `${lTime} - ${log.title}`
                        }).join('\n')

                    return (
                        <div
                            key={index}
                            className='detailedLogsBodyRow'
                            onMouseEnter={() => setHoveredLine(index)}
                            onMouseLeave={() => setHoveredLine(null)}
                            style={{ color: log.title === customInactiveTxt ? 'var(--text-color-subtlerer)' : 'var(--text-color-subtle)' }}
                        >
                            {/* Row */}
                            <SelectButton check={(e) => handleSelectLine(index, e)} isChecked={selectedLines.includes(index)} />
                            <div className='detailedLogsBodyTitle'>{date}</div>
                            <div className='detailedLogsBodyTitle'>{time}</div>
                            <div className='detailedLogsBodyTitle'>
                                {/* Log entries button */}
                                <ModularButton
                                    ref={lineLogEntriesPopupRefs.current[index]}
                                    iconEnd={<ListIcon size={remToPixels(1.2)} />}
                                    popupId={'popup-' + index}
                                    style={{ padding: '0.3rem', marginRight: '0.2rem', filter: hoveredLine === index ? 'opacity(1)' : 'opacity(0.5)' }}
                                    onClick={() => setLineInEditMode(null)}
                                    popupAlignmentVertical='top'
                                    popupAlignmentHorizontal='left'
                                    popupContent={
                                        <div style={{ padding: '0.5rem 0.2rem', maxWidth: '30rem' }}>
                                            {allLogsStr.split('\n').map((line, i) => (
                                                <div
                                                    className='logEntriesPopupLine'
                                                    style={{
                                                        borderRadius: '0.5rem',
                                                        fontSize: '0.8rem',
                                                        padding: '0 0.5rem',
                                                        color: line.includes(log.title) ? 'var(--text-color-subtle)' : 'var(--text-color-subtlerer)',
                                                        cursor: line.includes(log.title) ? 'default' : 'pointer',
                                                        backgroundColor: line.includes(log.title) ? 'transparent' : '',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        whiteSpace: 'nowrap',
                                                    }}
                                                    onClick={() => {
                                                        !line.includes(log.title) && handleChangeTitle(i, index)
                                                        lineLogEntriesPopupRefs.current[index].current.closePopup()
                                                    }}
                                                    key={i}
                                                >
                                                    {line}
                                                </div>
                                            ))}
                                            <div
                                                style={{
                                                    margin: '0.5rem 0.5rem 0rem 0.5rem',
                                                    marginTop: '0.2rem',
                                                    paddingTop: '0.3rem',
                                                    fontSize: '0.8rem',
                                                    color: 'var(--text-color-subtlerer)',
                                                    fontWeight: 600,
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'center',
                                                    borderTop: '1px solid var(--light-border)',
                                                    gap: '1rem'
                                                }}
                                            >
                                                <div
                                                    style={{ color: 'var(--text-color-subtle)', cursor: 'pointer' }}
                                                    onClick={() => { setLineInEditMode(index); lineLogEntriesPopupRefs.current[index].current.closePopup() }}
                                                >
                                                    Choose a different title
                                                </div>
                                                <div>Press [Esc] to close</div>
                                            </div> 
                                        </div>
                                    }
                                />
                                {/* Row title */}
                                <div
                                    title={log.title}
                                    style={{
                                        maxLines: 1,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        width: '100%',
                                    }}
                                >
                                    {/* Change title input */}
                                    {EditingLine ?
                                        <input
                                            type='text'
                                            defaultValue={log.title}
                                            onChange={(e) => setEditedTitle(e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === 'Escape') {
                                                    setLineInEditMode(null)
                                                }

                                                if (e.key === 'Enter' || e.key === 'Tab') {
                                                    handleChangeCustomTitle(index, log)
                                                }
                                            }}
                                            autoFocus
                                            className='detailedLogsBodyRowInput'
                                        />
                                        :
                                        log.title
                                    }
                                </div>
                                {/* Edit title button */}
                                {!selectedLines.length && <>
                                    <ModularButton
                                        iconEnd={!EditingLine ? <EditPencilIcon size={remToPixels(1.2)} /> : <SaveIcon size={remToPixels(1.2)} />}
                                        onClick={() => { if (!EditingLine) { setLineInEditMode(index); setEditedTitle(log.title) } else handleChangeCustomTitle(index, log) }}
                                        style={{ padding: '0.3rem', margin: '0 0.2rem', fontSize: '0.8rem', gap: '0.3rem', filter: (hoveredLine === index || EditingLine) ? 'opacity(1)' : 'opacity(0)' }}
                                    />
                                </>}
                                {/* Log tags */}
                                {log?.tags?.length > 0 &&
                                    <div className='detailedLogsRowTags'>
                                        {log.tags
                                            .sort((a, b) => {
                                                if ((a.category === '') && (b.category !== '')) return -1
                                                if ((a.category !== '') && (b.category === '')) return 1

                                                const aIndex = userTags.findIndex(t => t.category === a.category)
                                                const bIndex = userTags.findIndex(t => t.category === b.category)
                                                return bIndex - aIndex
                                            })
                                            .map((tag, i) => {
                                                const color = userTags.find(t => t.category === tag.category)?.color
                                                const backgroundColor = color ? tagBackgroundColor(color) : 'rgba(var(--primary-color-rgb), 0.2)'
                                                const borderColor = color ? `${tagBorderColor(color)}` : 'rgba(var(--primary-color-rgb), 0.2)'
                                                const textColor = color ? tagColor(color) : 'rgba(var(--primary-color-rgb), 0.7)'
                                                const tagIsForJira = tag.category !== '' && tag.category === userDoc.settings.integrations.jira.tagCategory
                                                let jiraUrl = ''
                                                if (tagIsForJira && userDoc.settings.integrations.jira.issueBaseUrl !== '') {
                                                    jiraUrl = userDoc.settings.integrations.jira.issueBaseUrl + 'browse/' + tag.value
                                                }    

                                                return (
                                                    <ModularButton
                                                        key={i}
                                                        title={tag.value}
                                                        tooltip={jiraUrl}
                                                        style={{
                                                            padding: '0.2rem 0.3rem 0.3rem 0.3rem',
                                                            fontSize: '0.8rem',
                                                            gap: '0.5rem',
                                                            borderRadius: '1rem 0.5rem 0.5rem 1rem',
                                                            backgroundColor: backgroundColor,
                                                            color: textColor,
                                                            border: `1px solid ${borderColor}`,
                                                        }}
                                                        border={true}
                                                        onIconClick={() => handleRemoveTag(index, log, tag.category)}
                                                        iconStart={<PlusCircleIcon color={textColor} rotate={45} size={oneRemToPixels} onClick={() => handleRemoveTag(index, log, tag.category)} />}
                                                        iconEnd={tagIsForJira ? <JiraIcon size={remToPixels(1)} /> : null}
                                                        onClick={() => {
                                                            if (!tagIsForJira) return

                                                            if (jiraUrl !== '') {
                                                                window.open(jiraUrl, '_blank')
                                                            }
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </div>
                                }
                                {!selectedLines.length && <>
                                    <ModularButton
                                        title='Add tag'
                                        iconEnd={<TagIcon size={remToPixels(1.2)} />}
                                        onClick={() => setNewTag({ category: '', value: '' })}
                                        style={{ padding: '0.3rem', marginLeft: '0.2rem', fontSize: '0.8rem', gap: '0.3rem', filter: hoveredLine === index ? 'opacity(1)' : 'opacity(0.5)' }}
                                        popupAlignmentVertical='bottom'
                                        popupAlignmentHorizontal='right'
                                        onClosePopupFunction={() => setTagAutoCompleteText('')}
                                        ref={lineAddTagPopupRefs.current[index]}
                                        popupContent={
                                            <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem' }}>
                                                <input
                                                    id={addTagInputKey}
                                                    type='text'
                                                    placeholder='Add a tag'
                                                    className='detailedLogsBodyRowInput'
                                                    style={{ width: 'auto', textAlign: 'left', padding: '0.2rem 0.5rem', maxWidth: '10rem' }}
                                                    autoFocus
                                                    autoComplete='off'
                                                    value={newTagValue}
                                                    onChange={(e) => setNewTagValue(e.target.value)}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            handleAddTag(index, log)
                                                            lineAddTagPopupRefs.current[index].current.closePopup()
                                                            setAddTagCategoryHovering(null)
                                                        }
                                                    }}
                                                />
                                                <span className='tagAutoComplete'>{tagAutoCompleteText}{tagAutoCompleteText && tagAutoCompleteValue !== newTagValue && <span className='tagAutoCompleteTab'>Tab</span>}</span>
                                                <VerticalSpacer size={0.3} />
                                                {userTags
                                                    .filter(tag => {
                                                        return (
                                                            (newTag.category === '' && !log.tags?.find(t => t.category === tag.category))
                                                            || (newTag.category !== '' && newTag.category === tag.category)
                                                        )
                                                    })
                                                    .map((tag, i) => (
                                                        <div
                                                            key={i}
                                                            className={'addTagCategory' + (tag.category === newTag.category ? ' addTagCategorySelected' : '')}
                                                            style={{
                                                                backgroundColor: tagBackgroundColor(tag.color),
                                                                color: tagColor(tag.color),
                                                                border: `1px solid ${tagBorderColor(tag.color)}`,
                                                            }}
                                                            onClick={(e) => {
                                                                setNewTag({ ...newTag, category: tag.category })
                                                                const input = document.getElementById(addTagInputKey)
                                                                input.focus()
                                                                setAddTagCategoryHovering(null)
                                                            }}
                                                            onMouseEnter={() => setAddTagCategoryHovering(i)}
                                                            onMouseLeave={() => setAddTagCategoryHovering(null)}
                                                        >
                                                            {tag.category}
                                                            <div
                                                                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                                                onClick={() => {
                                                                    setTimeout(() => setNewTag({ ...newTag, category: '' }), 0)
                                                                    const input = document.getElementById(addTagInputKey)
                                                                    input.focus()
                                                                    setAddTagCategoryHovering(null)
                                                                }}
                                                            >
                                                                {tag.category === newTag.category ?
                                                                    <PlusCircleIcon color={tagColor(tag.color)} rotate={45} size={oneRemToPixels} />
                                                                    :
                                                                    <CheckmarkIcon style={{ transition: 'all 0.2s', opacity: addTagCategoryHovering === i ? 1 : 0 }} color={tagColor(tag.color)} size={oneRemToPixels} />
                                                                }
                                                            </div>
                                                        </div>
                                                    ))}
                                            </div>
                                        }
                                    />
                                </>}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    </>
}

export default DetailedLogsTable