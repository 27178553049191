// Imports
import '../styles/App.css'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

// Pages
import Home from './Home.jsx'
import Login from './Login.jsx'
import Register from './Register.jsx'
import SetupWizard from './SetupWizard.jsx'
import UpdateWizard from './UpdateWizard.jsx'
import Settings from './Settings.jsx'
import NotFound from './NotFound.jsx'
import UnderConstruction from './UnderConstruction.jsx'
import Dashboard from './Dashboard.jsx'

// Providers
import { GlobalsProvider } from '../mgt/GlobalsProvider.js'
import { QueueProvider } from '../mgt/QueueProvider.js'

function App() {
  return (
    <GlobalsProvider>
      <QueueProvider>
        <div className="App">
          <Router>
            <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/login' element={<Login />} />
              <Route path='/register' element={<Register />} />
              <Route path='/setup' element={<SetupWizard />} />
              <Route path='/update' element={<UpdateWizard />} />
              <Route path='/u/:userId' element={<Dashboard />} />
              <Route path='/dashboard' element={<Dashboard />} />
              <Route path='/settings' element={<Settings />} />
              <Route path='*' element={<NotFound />} />
              <Route path='/under-construction' element={<UnderConstruction />} />
            </Routes>
          </Router>
        </div>
      </QueueProvider>
    </GlobalsProvider>
  )
}

export default App
