import { v4 as uuidv4 } from 'uuid'
import CryptoJS from 'crypto-js'

const APP_VERSION = '0.9.6'
const inactiveTxt = '://Inactive'
const customInactiveTxt = '(Inactive)'
const authExpirationHours = 24 * 7
const userDataExpirationHours = 24
const localStoragePageKey = (pageId => `pageDoc-${pageId}`)
const AstroIdCharacters = 'abcdefghijklmnopqrstuvwxyz0123456789'
function generateGuid() { return uuidv4() }
function generateAstroId() {
    return Array.from({ length: 50 }, () => {
        const randomByte = CryptoJS.lib.WordArray.random(1)
        const charIndex = randomByte.words[0] % AstroIdCharacters.length
        return AstroIdCharacters.charAt(charIndex)
    }).join('')
}
const localStorageKeys = {
    hostId: 'hostId',
    userDoc: 'userDoc',
    accessKey: 'accessKey',
    userComputers: 'userComputers',
    userComputersLastUpdated: 'userComputersLastUpdated',
    userComputerLogs: 'userComputerLogs',
    userComputerLogsLastUpdated: 'userComputerLogsLastUpdated',
    selectedLogsView: 'selectedLogsView',
}
const downloadUrl = 'https://firebasestorage.googleapis.com/v0/b/time-logify.appspot.com/o/TimeLogifySetup.zip?alt=media&token=6715fa54-ec4c-4e91-9851-a347b61a58db'
const defaultScheduleSettings = {
    monday: 8,
    tuesday: 8,
    wednesday: 8,
    thursday: 8,
    friday: 7,
    saturday: 0,
    sunday: 0
}
const releaseNotes = {
    '0.9.21': [
        'Added a setting to automatically start logging when you log into your computer.',
    ],
    '0.9.5': [
        'Added a setting to link your Jira account to TimeLogify.',
        'Added a button to view all the Jira issues you worked on for a specific day.',
        'Added an overview of the total time spent on Jira issues and the total time already logged.'
    ],
    '0.9.6': [
        'Log your work to Jira directly from TimeLogify.',
    ]
}

const ruleParameters = {
    sources: [
        {
            key: 'newTag',
            caption: 'new tag'
        },
        {
            key: 'title',
            caption: 'title'
        }
    ],
    conditions: [
        {
            key: 'contains',
            caption: 'contains'
        },
        {
            key: 'startsWith',
            caption: 'starts with'
        },
        {
            key: 'endsWith',
            caption: 'ends with'
        },
        {
            key: 'equals',
            caption: 'equals'
        },
    ],
    actions: [
        {
            key: 'setTagCategory',
            caption: 'set the tag category to',
            // sourceCompatible: ['newTag']
        },
        {
            key: 'changeTitle',
            caption: 'change the title to',
            // sourceCompatible: ['title']
        },
        {
            key: 'addTag',
            caption: 'add the following tag',
            // sourceCompatible: ['newTag', 'title']
        }
    ]
}

const tagColorsOld = [
    "#FFADAD", // Pastel Red
    "#FFD6A5", // Pastel Orange
    "#FDFFB6", // Pastel Yellow
    "#CAFFBF", // Pastel Green
    "#9BF6FF", // Pastel Cyan
    "#A0C4FF", // Pastel Blue
    "#BDB2FF", // Pastel Purple
    "#FFC6FF", // Pastel Pink
    "#FFAFCC", // Pastel Coral
    "#D4A5A5", // Pastel Mauve
    "#C2C4C2", // Pastel Gray
    "#F2FFDF"  // Pastel Lime
];

const tagColors = [
    // Blue-ish
    '#AFEEEE',
    '#87CEEB',
    '#659bff',
    '#4c65b1',
    // Green-ish
    '#81ecc0',
    '#57daa3',
    '#3fa078',
    '#008b51',
    // Red-ish
    "#CAFFBF",
    '#fff68f',
    '#FFA07A',
    '#ff7860',
    // Purple-ish
    '#d8bfd8',
    '#dda0dd',
    '#ba55d3',
    '#7c297c',
];

const getDefaultTagColor = () => tagColors[0];

const tagColor = (color) => darkerHexColor(color, 0.05);
const tagBorderColor = (color) => darkerHexColor(color, -0.4);
const tagBackgroundColor = (color) => darkerHexColor(color, -0.55);

function darkerHexColor(hex, percent) {
    // Ensure hex has the correct format (e.g., #RRGGBB)
    let color = hex.startsWith("#") ? hex.slice(1) : hex;

    // If the hex value is shorthand (e.g., #RGB), convert it to full form (e.g., #RRGGBB)
    if (color.length === 3) {
        color = color.split("").map(c => c + c).join("");
    }

    // Parse the color into R, G, B components
    const num = parseInt(color, 16);
    let r = (num >> 16);
    let g = ((num >> 8) & 0x00ff);
    let b = (num & 0x0000ff);

    // Adjust each color component based on the percentage
    r = Math.max(0, Math.min(255, Math.floor(r * (1 + percent))));
    g = Math.max(0, Math.min(255, Math.floor(g * (1 + percent))));
    b = Math.max(0, Math.min(255, Math.floor(b * (1 + percent))));

    // Convert back to hex and return
    const newColor = ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    return `#${newColor}`;
}


export {
    APP_VERSION,
    authExpirationHours,
    userDataExpirationHours,
    localStoragePageKey,
    AstroIdCharacters,
    generateGuid,
    generateAstroId,
    localStorageKeys,
    downloadUrl,
    defaultScheduleSettings,
    inactiveTxt,
    customInactiveTxt,
    tagColors,
    darkerHexColor,
    ruleParameters,
    tagBackgroundColor,
    tagColor,
    tagBorderColor,
    getDefaultTagColor,
    releaseNotes
}