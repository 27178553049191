// Imports
import { format } from 'date-fns';
import React, { useEffect, useState } from 'react'

// Components
import { PulsatingLight } from './Icons';

// Functions
import { convertTimestampToDate } from '../mgt/timeMgt';
import { remToPixels } from '../mgt/layoutMgt';
import { customInactiveTxt } from '../mgt/constants';
import { useGlobals } from '../mgt/GlobalsProvider';

const CircularProgress = ({ schedule, size, selectedDate, isLoggingActive }) => {
    const { logs } = useGlobals()
    const width = size / 5
    const radius = 10
    const viewport = radius * 2 + width
    const circumference = 2 * Math.PI * radius
    const [hoursLogged, setHoursLogged] = useState(0)

    let total = 0
    Object.keys(schedule).forEach(key => {
        total += schedule[key]
    })

    function calculateHoursLoggedThisWeek() {
        const firstDayOfWeek = new Date(selectedDate);
        const dayNo = selectedDate.getDay(); // (0 = Sunday, 6 = Saturday)
        const offset = (dayNo === 0 ? -6 : 1 - dayNo);
        firstDayOfWeek.setDate(selectedDate.getDate() + offset);
        const weekDays = []
        const startDate = new Date(firstDayOfWeek)
        for (let i = 0; i < 7; i++) {
            const dateStr = format(startDate, 'dd/MM/yyyy')
            weekDays.push(dateStr)
            startDate.setDate(startDate.getDate() + 1)
        }

        const weekLogs = logs.filter(log => {
            const logDateStr = format(convertTimestampToDate(log.startTime), 'dd/MM/yyyy')
            const isInactive = log.title === customInactiveTxt
            return !isInactive && weekDays.includes(logDateStr)
        })

        if (!weekLogs.length) {
            setHoursLogged(0)
        } else {
            let totalMinutes = 0
            weekLogs.forEach(log => {
                totalMinutes += log.logs.length
            })
            const totalHours = (totalMinutes / 60).toFixed(2)
            setHoursLogged(totalHours)
        }
    }

    useEffect(() => {
        if (!logs?.length || !selectedDate) return

        calculateHoursLoggedThisWeek()
    }, [logs, selectedDate])


    // Calculate the percentage of the circle that should be filled
    const percentage = Math.min(hoursLogged / total, 1)
    const dashArray = circumference * percentage

    const backgroundColor = '#a6c5e229'
    const progressColor = '#00a2bf'

    const oneRemToPX = remToPixels(1)

    return (
        <div className='circularProgressWrapper' style={{ maxHeight: size + 'rem', maxWidth: size + 'rem' }} >
            <div className='circularProgressOverlay'>
                <div className='circularProgressPercentage'>{(hoursLogged / total * 100).toFixed(2)}%</div>
                <div className='circularProgressText'>{hoursLogged}/{total}h</div>
                <div className='circularProgressStatus'>
                    <PulsatingLight active={isLoggingActive} size={oneRemToPX} />
                    <div className='circularProgressStatusTxt'>
                        {isLoggingActive ? 'Logging' : 'Not logging'}
                    </div>
                </div>
            </div>
            <svg
                viewBox={`0 0 ${viewport} ${viewport}`}
                className="circularProgress"
                preserveAspectRatio="xMidYMid meet"
            >
                <circle
                    cx={radius + width / 2}
                    cy={radius + width / 2}
                    r={radius}
                    stroke={backgroundColor}
                    strokeWidth={width}
                    fill="none"
                />
                <circle
                    cx={radius + width / 2}
                    cy={radius + width / 2}
                    r={radius}
                    stroke={progressColor}
                    strokeWidth={width}
                    fill="none"
                    strokeDasharray={`${dashArray} ${circumference}`}
                    transform={`rotate(-90 ${radius + width / 2} ${radius + width / 2})`} // Rotate to start the circle at the top
                />
            </svg>
        </div>
    )
}

export default CircularProgress