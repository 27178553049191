import React from 'react'
import FeedbackForm from './FeedbackForm'
import VerticalSpacer from './VerticalSpacer'
import ModularButton from './ModularButton'

const Sidebar = ({ content, userComputers, computer, user, setSettingsPage }) => {

    const Content = () => {
        switch (content) {
            case 'settings':
                return <>
                    <div className='sidebarTitle'>Settings</div>
                    <ModularButton
                        title='General'
                        onClick={() => setSettingsPage('general')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Tags'
                        onClick={() => setSettingsPage('tags')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Schedule'
                        onClick={() => setSettingsPage('schedule')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                    <VerticalSpacer size={1} />
                    <ModularButton
                        title='Integrations'
                        onClick={() => setSettingsPage('integrations')}
                        border={true}
                        style={{ width: 'calc(100% - 4rem)', height: '2rem' }}
                    />
                </>
            case 'computers':
                return <>
                    <div className='sidebarTitle'>Your computers</div>
                    {userComputers.map((userComputer, index) => {
                        const isCurrent = userComputer.hostId === computer.hostId

                        return (
                            <div
                                key={index} className='sidebarComputer'
                                style={{
                                    borderColor: isCurrent ? 'var(--text-color-subtle)' : 'var(--text-color-subtler)',
                                    cursor: isCurrent ? 'default' : 'pointer'
                                }}
                            >
                                {/* TODO: Edit computer name functionality */}
                                {/* <div className='sidebarComputerEditIcon'>
                                    <EditIcon />
                                </div> */}
                                <div className='sidebarComputerName' style={{ color: isCurrent && 'white' }}>{computer.hostName}</div>
                                <div className='sidebarComputerId'>{computer.hostId}</div>
                            </div>
                        )
                    })}
                </>
            default:
                return null
        }
    }

    return (
        <div className='Sidebar'>
            <Content />
            <FeedbackForm user={user} />
        </div>
    )
}

export default Sidebar