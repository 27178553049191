import React, { useEffect, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import VerticalSpacer from '../components/VerticalSpacer'
import ModularButton from '../components/ModularButton'
import { useNavigate } from 'react-router-dom'
import LoadingText from '../components/LoadingText'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as firestoreAPI from '../mgt/firestoreAPI'

const UnderConstruction = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [isNewUser, setIsNewUser] = useState(true)
    const [loadingComplete, setLoadingComplete] = useState(false)

    useEffect(() => {
        if (loading) return
        if (!user) {
            navigate('/')
            return
        }

        checkIsNewUser()
    }, [user, loading])

    async function checkIsNewUser() {
        const allHosts = await firestoreAPI.getUserComputerDocuments(user.uid)
        if (allHosts.length > 0) {
            setIsNewUser(false)
        }
        setLoadingComplete(true)
    }

    if (!loadingComplete) return <LoadingText />

    return <>
        <HeaderBar />
        <div className='setupWizard'>
            <div className='homeSection' >
                <div className='homeSubtitle'>🚧👷 TimeLogify is under construction...</div>
                <div className='homeSpacer' />
                <div className='homeSubtext'>I might have changed too much at once, and the website might be broken now.</div>
                <VerticalSpacer />
                {!isNewUser &&
                    <div className='homeSubtext'>Make sure TimeLogify is running to keep logging your work.</div>
                }
                <div className='homeSubtext'>It'll be back soon, thank you for your patience! 🫶</div>
                <div className='verticalSpacerLarge' />
            </div>
            {!isNewUser &&
                <ModularButton
                    title='View your old logs'
                onClick={() => navigate('/dashboard' + '?legacy=true')}
                    border={true}
                />
            }
        </div>
    </>
}

export default UnderConstruction