import React from 'react'
import { handleSignOut } from '../mgt/authMgt'
import { useLocation, useNavigate } from 'react-router-dom'
import { LogOutIcon } from './Icons'
import { useAuthState } from 'react-firebase-hooks/auth'
import { auth } from '../mgt/firebase'
import ModularButton from './ModularButton'

function HeaderBar(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const [user] = useAuthState(auth)

    async function handleAuthAction() {
        if (user) {
            await handleSignOut()
            navigate('/')
        } else {
            navigate('/login')
        }
    }

    return <>
        <div className="headerBar">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div
                    className='headerTitle'
                    onClick={() => navigate('/')}
                >
                    TimeLogify
                </div>

                <div className='horizontalSpacer' />
                <div className='headerSpacer' />
                <div className='horizontalSpacer' />

                {user &&
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        {/* <ModularButton
                            title='Profile'
                            onClick={() => navigate('/profile')}
                            border={false}
                            isActive={location.pathname === '/profile'}
                        /> */}
                        <ModularButton
                            title='Dashboard'
                            onClick={() => user?.uid && navigate('/dashboard')}
                            border={false}
                            isActive={location.pathname === '/dashboard'}
                        />
                        <ModularButton
                            title='Settings'
                            onClick={() => navigate('/settings')}
                            border={false}
                            isActive={location.pathname === '/settings'}
                        />
                    </div>
                }
            </div>

            <div style={{ gap: '0.5rem', display: 'flex' }}>
                <ModularButton
                    title='About'
                    onClick={() => navigate('/about')}
                    border={false}
                    isActive={location.pathname === '/about'}
                />
                <ModularButton
                    title='Pricing'
                    onClick={() => navigate('/pricing')}
                    border={false}
                    isActive={location.pathname === '/pricing'}
                />
                <ModularButton
                    title='FAQ'
                    onClick={() => navigate('/faq')}
                    border={false}
                    isActive={location.pathname === '/faq'}
                />
                <ModularButton
                    title='Help'
                    onClick={() => navigate('/help')}
                    border={false}
                    isActive={location.pathname === '/help'}
                />
                <ModularButton
                    title={user ? 'Log out' : 'Log in'}
                    onClick={handleAuthAction}
                    border={true}
                    iconEnd={user && <LogOutIcon size={22} />}
                    noGap={true}
                />
            </div>
        </div>
        <div className='headerVerticalSpacer' />

        {!props.noMargin && <>
            <div className='verticalSpacerLarge' />
            <div className='verticalSpacerLarge' />
        </>}
    </>
}

export default HeaderBar