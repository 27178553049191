import React, { useEffect, useState } from 'react'
import VerticalSpacer from '../../components/VerticalSpacer'
import ModularButton from '../../components/ModularButton'
import * as TimeLogifyAPI from '../../mgt/timeLogifyAPI'
import { useGlobals } from '../../mgt/GlobalsProvider'
import { modifyUserDocument } from '../../mgt/firestoreAPI'

const General = () => {
    const [autoStartEnabled, setAutoStartEnabled] = useState(false)
    const { userDoc, setUserDoc } = useGlobals()

    async function toggleAutoStart() {
        if (TimeLogifyAPI.setAutoStartEnabled(!autoStartEnabled)) {
            setAutoStartEnabled(!autoStartEnabled)
        }

        const newUserDoc = { ...userDoc }
        userDoc.settings.general.autoStart = !autoStartEnabled
        setUserDoc(newUserDoc)
        modifyUserDocument(newUserDoc)
    }

    useEffect(() => {
        if (!userDoc) return

        setAutoStartEnabled(userDoc.settings.general.autoStart)
    }, [userDoc])

    return <>
        <VerticalSpacer size={5} />

        <div className='settingsTitle'>Automatically start logging</div>
        <div className='settingsSubtitle'>Enable this to automatically start logging when you log into your computer.</div>

        <VerticalSpacer size={1} />

        <ModularButton
            title={autoStartEnabled ? 'Enabled' : 'Disabled'}
            border={true}
            onClick={toggleAutoStart}
            style={{
                color: autoStartEnabled ? '#4caf50' : 'var(--text-color-subtle)',
                borderColor: autoStartEnabled ? '#4caf50' : 'var(--lighter-border)',
                marginLeft: '5rem'
            }}
        />
    </>
}

export default General