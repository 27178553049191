// Imports:
import { auth, googleProvider } from './firebase'
import * as firestoreAPI from './firestoreAPI'
import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, signInWithPopup, signOut } from 'firebase/auth'
import { clearLocalStorage } from './cachingMgt'

// Firebase Authentication:
async function handleSignInWithPopupGoogle() {
    try {
        console.log('[FIREBASE] Signing in with Google')
        const result = await signInWithPopup(auth, googleProvider)
        const user = result.user
        const userDoc = await firestoreAPI.getUserDocument(user.uid)
        return userDoc
    } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        const email = error.customData.email
        const credential = GoogleAuthProvider.credentialFromError(error)
        console.log('Google Sign in error:', errorCode, errorMessage, email, credential)
        return false
    }
}

async function handleRegisterWithEmail(email, password, name) {
    try {
        console.log('[FIREBASE] Registering with Email:', email)
        const result = await createUserWithEmailAndPassword(auth, email.toLowerCase(), password)
        const user = result.user
        const userDoc = await firestoreAPI.getUserDocument(user.uid, name)
        return userDoc
    } catch (error) {
        const errorCode = error.code
        const errorMessage = error.message
        if (errorCode === 'auth/email-already-in-use') {
            try {
                const result = await signInWithEmailAndPassword(auth, email.toLowerCase(), password)
                const user = result.user
                const userDoc = await firestoreAPI.getUserDocument(user.uid)
                return userDoc
            } catch (error) {
                console.log('Email Sign in error:', error.code, error.message)
            }
        } else {
            console.log('Email Sign in error:', errorCode, errorMessage)
        }
        return false
    }
}

async function handleSignInWithEmail(email, password) {
    try {
        console.log('[FIREBASE] Signing in with Email:', email)
        const result = await signInWithEmailAndPassword(auth, email.toLowerCase(), password)
        const user = result.user
        const userDoc = await firestoreAPI.getUserDocument(user.uid)
        return {
            success: true,
            userDoc: userDoc,
        }
    } catch (error) {
        const errorCode = error.code
        var errorMessage = error.message
        console.log('Email Sign in error:', errorCode, errorMessage)
        if (errorCode === 'auth/invalid-credential') {
            errorMessage = 'Your email or password is incorrect'
        }
        if (errorCode === 'auth/too-many-requests') {
            errorMessage = 'Too many attempts. Please try again later.'
        }

        return {
            success: false,
            errorCode: errorCode,
            errorMessage: errorMessage,
        }
    }
}

async function handleSignOut() {
    clearLocalStorage()
    await signOut(auth).then(() => {
        console.log('[FIREBASE] Auth signed out')
    }).catch((error) => {
        console.log(error)
    })
}

export {
    handleSignInWithPopupGoogle,
    handleRegisterWithEmail,
    handleSignInWithEmail,
    handleSignOut,
}