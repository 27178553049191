import React, { createRef, useEffect, useRef, useState } from 'react'
import HeaderBar from '../components/HeaderBar'
import Sidebar from '../components/Sidebar'
import VerticalSpacer from '../components/VerticalSpacer'
import { EnterKeyIcon, MenuDotsIcon, MinusCircleIcon, PlusCircleIcon, TagHoleCircleIcon } from '../components/Icons'
import ModularButton from '../components/ModularButton'
import { defaultScheduleSettings, ruleParameters, tagBackgroundColor, tagBorderColor, tagColor, tagColors, getDefaultTagColor } from '../mgt/constants'
import * as firestoreAPI from '../mgt/firestoreAPI'
import { useNavigate } from 'react-router-dom'
import EasterEgg from '../components/EasterEgg'
import { remToPixels } from '../mgt/layoutMgt'
import { useGlobals } from '../mgt/GlobalsProvider'
import HorizontalSpacer from '../components/HorizontalSpacer'
import Integrations from '../pages/settings/Integrations.jsx'
import General from './settings/General.jsx'

const oneRemToPixels = remToPixels(1)

const Settings = () => {
    const navigate = useNavigate()
    const { userDoc, user, loading } = useGlobals()
    const smallBtnStyle = { fontSize: '1rem', padding: '0.25rem 0.5rem', margin: '0.25rem 0.5rem' }
    const ruleParamBtnStyle = { ...smallBtnStyle, color: 'white', padding: '0.2rem 0.5rem', margin: '0', transform: 'translateY(0.0125rem)' }

    const [settingsPage, setSettingsPage] = useState('general')

    const [schedule, setSchedule] = useState({})
    const [newSchedule, setNewSchedule] = useState({})

    const [tagCategories, setTagCategories] = useState([])
    const [newTagCategoryName, setNewTagCategoryName] = useState('')
    const [addingNewTagCategory, setAddingNewTagCategory] = useState(false)
    const deleteTagCategoryBtnRefs = useRef([])
    const changeTagColorBtnRefs = useRef([])
    const categoryScrollRef = useRef(null)

    const [rules, setRules] = useState([])
    const [newRule, setNewRule] = useState({ source: 0, condition: 0, conditionValue: '', action: 0, actionValue: '' })
    const [addingNewRule, setAddingNewRule] = useState(false)
    const ruleSourceBtnRefs = useRef([])
    const ruleConditionBtnRefs = useRef([])
    const ruleActionBtnRefs = useRef([])
    const ruleActionValueBtnRefs = useRef([])
    const ruleDeleteBtnRefs = useRef([])
    const ruleScrollRef = useRef(null)

    useEffect(() => {
        const keyDownHandler = (e) => {
            console.log(e.key)
            if (e.key === 'Escape') {
                resetNewRule()
            }
            if (e.key === 'Enter') {
                handleSaveNewRule()
            }
        }

        if (addingNewRule) {
            document.addEventListener('keydown', keyDownHandler)
        } else {
            document.removeEventListener('keydown', keyDownHandler)
        }

        return () => document.removeEventListener('keydown', keyDownHandler)
    }, [addingNewRule])


    function resetNewRule() {
        setAddingNewRule(false);
        const newRules = rules.filter(rule => !rule.new)
        setRules(newRules)
        setNewRule({ source: 0, condition: 0, conditionValue: '', action: 0, actionValue: '' })
    }

    function addNewRule() {
        if (rules.find(rule => rule.new)) return

        setAddingNewRule(true)
        const newRuleEntry = {
            source: 'newTag',
            condition: 'contains',
            conditionValue: '',
            action: 'setTagCategory',
            actionValue: tagCategories[0].category,
            new: true
        }
        setRules([...rules, newRuleEntry])
        updateRulesBtnRefs([...rules, newRuleEntry])

        const newRule = {
            source: 0,
            condition: 0,
            conditionValue: '',
            action: 0,
            actionValue: tagCategories[0].category
        }
        setNewRule(newRule)
    }

    function updateCategoryBtnRefs(categories) {
        if (categories.length === 0) return
        deleteTagCategoryBtnRefs.current = [];
        changeTagColorBtnRefs.current = [];
        deleteTagCategoryBtnRefs.current = categories.map((_, i) => deleteTagCategoryBtnRefs.current[i] || createRef())
        changeTagColorBtnRefs.current = categories.map((_, i) => changeTagColorBtnRefs.current[i] || createRef())
    }

    function updateRulesBtnRefs(rules) {
        if (rules.length === 0) return
        ruleSourceBtnRefs.current = rules.map((_, i) => ruleSourceBtnRefs.current[i] || createRef())
        ruleConditionBtnRefs.current = rules.map((_, i) => ruleConditionBtnRefs.current[i] || createRef())
        ruleActionBtnRefs.current = rules.map((_, i) => ruleActionBtnRefs.current[i] || createRef())
        ruleActionValueBtnRefs.current = rules.map((_, i) => ruleActionValueBtnRefs.current[i] || createRef())
        ruleDeleteBtnRefs.current = rules.map((_, i) => ruleDeleteBtnRefs.current[i] || createRef())
    }

    function updateSchedule(day, hoursDelta) {
        const newHours = newSchedule[day] + hoursDelta
        if (newHours < 0 || newHours > 24) return
        setNewSchedule({ ...newSchedule, [day]: newHours })
    }

    useEffect(() => {
        if (ruleScrollRef.current) {
            ruleScrollRef.current.scrollTop = ruleScrollRef.current.scrollHeight;
        }
    }, [rules]);

    useEffect(() => {
        if (categoryScrollRef.current) {
            categoryScrollRef.current.scrollTop = categoryScrollRef.current.scrollHeight;
        }
    }, [addingNewTagCategory]);

    useEffect(() => {
        if (loading) return
        if (!user) {
            navigate('/login')
            return
        }
    }, [loading, user])

    useEffect(() => {
        userDoc && loadSettings()
    }, [userDoc])

    async function loadSettings() {
        const userSchedule = userDoc?.settings?.schedule || defaultScheduleSettings
        setSchedule(userSchedule)
        setNewSchedule(userSchedule)
        setTagCategories(userDoc?.settings?.tags || [])
        updateCategoryBtnRefs(userDoc?.settings?.tags || [])
        setRules(userDoc?.settings?.rules || [])
        updateRulesBtnRefs(userDoc?.settings?.rules || [])
    }

    async function saveSchedule() {
        userDoc.settings.schedule = newSchedule
        await firestoreAPI.modifyUserDocument(userDoc)
        setSchedule(newSchedule)
    }

    async function handleAddNewTagCategory() {
        if (newTagCategoryName.length === 0) return

        if (!userDoc.settings?.tags) userDoc.settings.tags = []
        const alreadyExists = userDoc.settings.tags.find(tag => tag.category === newTagCategoryName)
        if (alreadyExists) return

        const newTag = {
            category: newTagCategoryName,
            color: tagColors[userDoc.settings.tags.length % tagColors.length]
        }

        userDoc.settings.tags.push(newTag)
        await firestoreAPI.modifyUserDocument(userDoc)
        setTagCategories([...userDoc.settings.tags])
        updateCategoryBtnRefs([...userDoc.settings.tags])

        setAddingNewTagCategory(false)
    }

    async function handleDeleteTagCategory(i) {
        const newRules = rules.filter(rule => rule.actionValue !== tagCategories[i].category)
        userDoc.settings.rules = newRules
        setRules(newRules)

        userDoc.settings.tags.splice(i, 1)
        setTagCategories([...userDoc.settings.tags])

        await firestoreAPI.modifyUserDocument(userDoc)
    }

    async function handleChangeTagColor(tagIndex, colorIndex) {
        userDoc.settings.tags[tagIndex].color = tagColors[colorIndex]
        await firestoreAPI.modifyUserDocument(userDoc)
        setTagCategories([...userDoc.settings.tags])
    }

    async function handleSaveNewRule() {
        let up2dateNewRule = {}

        setNewRule((liveNewRule) => {
            up2dateNewRule = { ...liveNewRule }
            return liveNewRule
        })
        const newSavedRule = {
            source: ruleParameters.sources[up2dateNewRule.source].key,
            condition: ruleParameters.conditions[up2dateNewRule.condition].key,
            conditionValue: up2dateNewRule.conditionValue,
            action: ruleParameters.actions[up2dateNewRule.action].key,
            actionValue: up2dateNewRule.actionValue,
            addTagValue: up2dateNewRule.addTagValue || '',
        }
        if (newSavedRule.conditionValue === '') return
        if (newSavedRule.action === 'changeTitle' && newSavedRule.actionValue === '') return
        if (newSavedRule.action === 'setTagCategory' && newSavedRule.actionValue === '') return
        if (newSavedRule.action === 'addTag' && (newSavedRule.actionValue === '' || newSavedRule.addTagValue === '')) return

        const newRules = rules.filter(rule => !rule.new)
        newRules.push(newSavedRule)
        userDoc.settings.rules = newRules
        await firestoreAPI.modifyUserDocument(userDoc)
        setRules(newRules)
        setNewRule({ source: 0, condition: 0, conditionValue: '', action: 0, actionValue: '' })
        setAddingNewRule(false)
    }

    return <>
        <HeaderBar noMargin={true} />

        <div className='settingsSection'>

            <Sidebar content={'settings'} user={user} setSettingsPage={setSettingsPage} />

            <div className='settingsMain customScroller'>

                {settingsPage === 'general' && <General />}

                {settingsPage === 'integrations' && <Integrations />}

                {/* Schedule Settings */}
                {settingsPage === 'schedule' && <>
                    <VerticalSpacer size={5} />

                    <div className='settingsTitle'>Schedule</div>
                    <div className='settingsSubtitle'>This will affect the way your data is displayed in your dashboard</div>

                    <div className='scheduleWrapper'>
                        {[
                            'Monday',
                            'Tuesday',
                            'Wednesday',
                            'Thursday',
                            'Friday',
                            'Saturday',
                            'Sunday'
                        ].map((day, index) => {
                            const hoursChanged = newSchedule[day.toLowerCase()] !== schedule[day.toLowerCase()]

                            return <div key={index} className='scheduleDay'>
                                <div className='scheduleDayName'>{day}</div>
                                <div className='scheduleDayButtons'>
                                    <ModularButton
                                        onClick={() => updateSchedule(day.toLowerCase(), -1)}
                                        glowIconOnHover={true}
                                        iconStart={<MinusCircleIcon size={20} color='var(--text-color-subtle)' />} />
                                    <div
                                        className='scheduleDayHours'
                                        style={{ color: hoursChanged ? 'white' : 'var(--text-color-subtle)' }}
                                    >
                                        {newSchedule[day.toLowerCase()]}
                                    </div>
                                    {/* TODO: Change to input */}
                                    {/* <input
                                        type='number'
                                        className='scheduleDayHours'
                                        value={newSchedule[day.toLowerCase()]}
                                        onChange={e => setNewSchedule({ ...newSchedule, [day.toLowerCase()]: e.target.value })}
                                        style={{ , color: hoursChanged ? 'white' : 'var(--text-color-subtle)' }}
                                    /> */}
                                    <ModularButton
                                        onClick={() => updateSchedule(day.toLowerCase(), 1)}
                                        glowIconOnHover={true}
                                        iconStart={<PlusCircleIcon size={20} color='var(--text-color-subtle)' />} />
                                </div>
                            </div>
                        })}
                        <div className={'scheduleSaveButtons'}>
                            <div className={'scheduleSaveButtonsInner' + (JSON.stringify(newSchedule) !== JSON.stringify(schedule) ? ' scheduleSaveButtonsInnerOpen' : '')}>
                                <ModularButton
                                    border={true}
                                    title='Save changes'
                                    onClick={saveSchedule}
                                />
                                <ModularButton
                                    title='Cancel'
                                    onClick={() => setNewSchedule(schedule)}
                                />
                            </div>
                        </div>
                    </div>
                </>}

                {/* Tag Settings */}
                {settingsPage === 'tags' && <>
                    <VerticalSpacer size={5} />
                    {/* Tag Categories */}
                    <div className='settingsTitle'>Categories</div>
                    <div className='settingsSubtitle'>Create categories to group your tags and apply custom rules</div>
                    <div className='settingsTagCategories'>
                        <div className="settingsTagCategoriesScroller" ref={categoryScrollRef}>
                            {tagCategories.map((tag, index) => {
                                return <div key={index} className='settingsTagCategory'>
                                    <div
                                        className='settingsTagCategoryName'
                                        style={{
                                            backgroundColor: tagBackgroundColor(tag.color),
                                            border: '1px solid ' + tagBorderColor(tag.color),
                                            color: tagColor(tag.color),
                                        }}
                                    >
                                        <TagHoleCircleIcon
                                            size={oneRemToPixels / 1.5}
                                            color={tagColor(tag.color)}
                                        />
                                        {tag.category}
                                    </div>
                                    <ModularButton
                                        title='Change color'
                                        style={{ ...smallBtnStyle, margin: '0.25rem 0.5rem 0.25rem auto' }}
                                        popupAlignmentVertical='center'
                                        popupAlignmentHorizontal='center'
                                        ref={changeTagColorBtnRefs.current[index]}
                                        popupContent={
                                            <div className='colorPickerPopup'>
                                                {tagColors.map((color, i) => {
                                                    return <div
                                                        key={i}
                                                        className='colorPicker'
                                                        style={{
                                                            display: 'inline-block',
                                                            backgroundColor: tagBackgroundColor(color),
                                                            border: '1px solid ' + tagBorderColor(color),
                                                            color: tagColor(color),
                                                        }}
                                                        onClick={() => {
                                                            handleChangeTagColor(index, i);
                                                            changeTagColorBtnRefs.current[index].current.closePopup()
                                                        }}
                                                    />
                                                }
                                                )}
                                            </div>
                                        }
                                    />
                                    <ModularButton
                                        iconEnd={<MenuDotsIcon size={remToPixels(1.2)} />}
                                        style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem' }}
                                        popupAlignmentVertical='top'
                                        popupAlignmentHorizontal='right'
                                        ref={deleteTagCategoryBtnRefs.current[index]}
                                        popupContent={
                                            <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem' }}>
                                                <ModularButton
                                                    title='Delete category'
                                                    style={{ ...smallBtnStyle, color: 'var(--text-color-danger)' }}
                                                    onClick={() => {
                                                        deleteTagCategoryBtnRefs.current[index].current.closePopup()
                                                        setTimeout(() => {
                                                            handleDeleteTagCategory(index);
                                                        }, 0)
                                                    }}
                                                />
                                            </div>
                                        }
                                    />
                                </div>
                            })}
                            {addingNewTagCategory &&
                                <div className='settingsTagCategory'>
                                    <input
                                        className='settingsTagCategoryInput'
                                        type='text'
                                        placeholder='Category name'
                                        style={{ borderColor: 'var(--text-color-subtler)', padding: '0.5rem' }}
                                        autoFocus
                                        onChange={e => setNewTagCategoryName(e.target.value)}
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                handleAddNewTagCategory()
                                            }
                                            if (e.key === 'Escape') {
                                                setAddingNewTagCategory(false)
                                                setNewTagCategoryName('')
                                            }
                                        }}
                                    />
                                    <HorizontalSpacer size={0.5} />
                                    <ModularButton
                                        title={newTagCategoryName === '' && '[Esc]'}
                                        style={{ ...ruleParamBtnStyle, padding: '0.35rem 0.5rem', marginLeft: 'auto', marginRight: '0.2rem', color: 'var(--text-color-subtle)' }}
                                        iconEnd={newTagCategoryName !== '' && <EnterKeyIcon size={oneRemToPixels} />}
                                        onClick={newTagCategoryName === '' ? () => setAddingNewTagCategory(false) : handleAddNewTagCategory}
                                    />
                                </div>
                            }
                        </div>
                        <div className='settingsTagCategoriesFooter' >
                            <ModularButton
                                title={addingNewTagCategory ? 'Cancel' : 'New category'}
                                noGap={true}
                                style={smallBtnStyle}
                                onClick={() => {
                                    setAddingNewTagCategory(!addingNewTagCategory)
                                }}
                                iconStart={<PlusCircleIcon size={oneRemToPixels} color='var(--text-color-subtle)' rotate={addingNewTagCategory ? 45 : 0} />}
                            />
                        </div>
                    </div>

                    {/* Tag Rules */}
                    {tagCategories.length > 0 && <>
                        <div className='settingsTitle'>Custom rules</div>
                        <div className='settingsSubtitle'>Set up rules to automatically categorize or modify your logs based on specific conditions</div>
                        <div className="settingsRules">
                            <div className="settingsRulesScroller" ref={ruleScrollRef}>
                                {rules
                                    .filter(rule => !rule.new)
                                    // .sort((a, b) => {
                                    //     // Sort by action
                                    //     if (ruleParameters.actions.findIndex(action => action.key === a.action) > ruleParameters.actions.findIndex(action => action.key === b.action)) return 1
                                    //     if (ruleParameters.actions.findIndex(action => action.key === a.action) < ruleParameters.actions.findIndex(action => action.key === b.action)) return -1
                                    //     // then by action value
                                    //     if (a.actionValue > b.actionValue) return 1
                                    //     if (a.actionValue < b.actionValue) return -1
                                    //     // then by source
                                    //     if (ruleParameters.sources.findIndex(source => source.key === a.source) > ruleParameters.sources.findIndex(source => source.key === b.source)) return 1
                                    //     if (ruleParameters.sources.findIndex(source => source.key === a.source) < ruleParameters.sources.findIndex(source => source.key === b.source)) return -1
                                    //     // then by condition
                                    //     if (ruleParameters.conditions.findIndex(condition => condition.key === a.condition) > ruleParameters.conditions.findIndex(condition => condition.key === b.condition)) return 1
                                    //     if (ruleParameters.conditions.findIndex(condition => condition.key === a.condition) < ruleParameters.conditions.findIndex(condition => condition.key === b.condition)) return -1
                                    //     // then by condition value
                                    //     if (a.conditionValue > b.conditionValue) return 1
                                    //     if (a.conditionValue < b.conditionValue) return -1
                                    //     return 0
                                    // })
                                    .map((rule, index) => {
                                        return <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} key={index}>
                                            <div className='savedSettingsRule'>
                                                <span>If the&nbsp;</span>
                                                <span>{ruleParameters.sources.find(source => source.key === rule.source).caption}</span>
                                                <span>&nbsp;</span>
                                                <span>{ruleParameters.conditions.find(condition => condition.key === rule.condition).caption}</span>
                                                <span>&nbsp;</span>
                                                <span style={{ color: 'var(--text-color-subtle)' }}>{rule.conditionValue}</span>
                                                <span>,&nbsp;</span>
                                                <span>{ruleParameters.actions.find(action => action.key === rule.action).caption}</span>
                                                <span>&nbsp;</span>
                                                {rule.action === 'changeTitle' && <span>'{rule.actionValue}'</span>}
                                                {rule.action === 'setTagCategory' &&
                                                    <span
                                                        style={{
                                                            borderRadius: '0.5rem',
                                                            color: tagColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            border: '1px solid ' + tagBorderColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            backgroundColor: tagBackgroundColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            padding: '0.1rem 0.25rem'
                                                        }}
                                                    >
                                                        {rule.actionValue}
                                                    </span>
                                                }
                                                {rule.action === 'addTag' &&
                                                    <span
                                                        style={{
                                                            borderRadius: '0.5rem',
                                                            color: tagColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            backgroundColor: tagBackgroundColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            border: '1px solid ' + tagBorderColor(tagCategories.find(tag => tag.category === rule.actionValue).color),
                                                            padding: '0.1rem 0.25rem'
                                                        }}
                                                    >
                                                        {rule.action === 'addTag' ? rule.actionValue + ': ' + rule.addTagValue : ''}
                                                    </span>
                                                }
                                            </div>
                                            <HorizontalSpacer size={2} />
                                            <ModularButton
                                                iconEnd={<MenuDotsIcon size={remToPixels(1.2)} />}
                                                style={{ padding: '0.3rem', fontSize: '0.8rem', gap: '0.3rem', marginRight: '0.5rem' }}
                                                popupAlignmentVertical='center'
                                                popupAlignmentHorizontal='right'
                                                ref={ruleDeleteBtnRefs.current[index]}
                                                popupContent={
                                                    <div style={{ padding: '0.2rem 0.2rem', maxWidth: '30rem' }}>
                                                        <ModularButton
                                                            title='Delete rule'
                                                            style={{ ...smallBtnStyle, color: 'var(--text-color-danger)' }}
                                                            onClick={() => {
                                                                ruleDeleteBtnRefs.current[index].current.closePopup()
                                                                setTimeout(() => {
                                                                    const newRules = rules.filter((_, i) => i !== index)
                                                                    setRules(newRules)
                                                                    firestoreAPI.modifyUserDocument({ ...userDoc, settings: { ...userDoc.settings, rules: newRules } })
                                                                }, 0)
                                                            }}
                                                        />
                                                    </div>
                                                }
                                            />
                                        </div>
                                    })}
                                {rules.filter(rule => rule.new).map((_, index) => {
                                    return (
                                        <div className='settingsRule' key={'new-' + index}>
                                            <div className="settingsRuleTxt">
                                                If the
                                            </div>
                                            {/* title - new tag */}
                                            <ModularButton
                                                title={ruleParameters.sources[newRule.source].caption}
                                                style={ruleParamBtnStyle}
                                                ref={ruleSourceBtnRefs.current[index]}
                                                popupAlignmentVertical='center'
                                                popupAlignmentHorizontal='center'
                                                popupContent={
                                                    <div className='ruleParamPopup'>
                                                        {ruleParameters.sources.map((source, i) => {
                                                            return <div
                                                                key={i}
                                                                className='ruleParamSelection'
                                                                style={{ display: 'inline-block' }}
                                                                onClick={() => {
                                                                    setNewRule((prev) => { return { ...prev, source: i } });
                                                                    ruleSourceBtnRefs.current[index].current.closePopup()
                                                                }}
                                                            >
                                                                {source.caption}
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            />
                                            {/* contains - equals - starts with - ends with */}
                                            <ModularButton
                                                title={ruleParameters.conditions[newRule.condition].caption}
                                                style={ruleParamBtnStyle}
                                                ref={ruleConditionBtnRefs.current[index]}
                                                popupAlignmentVertical='center'
                                                popupAlignmentHorizontal='center'
                                                popupContent={
                                                    <div className='ruleParamPopup'>
                                                        {ruleParameters.conditions.map((condition, i) => {
                                                            return <div
                                                                key={i}
                                                                className='ruleParamSelection'
                                                                style={{ display: 'inline-block' }}
                                                                onClick={() => {
                                                                    setNewRule((prev) => { return { ...prev, condition: i } });
                                                                    ruleConditionBtnRefs.current[index].current.closePopup()
                                                                }}
                                                            >
                                                                {condition.caption}
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            />
                                            {/* rule input */}
                                            <input
                                                type='text'
                                                className='settingsRuleInput'
                                                placeholder='value…'
                                                onChange={e => setNewRule((prev) => { return { ...prev, conditionValue: e.target.value } })}
                                            />
                                            <div className="settingsRuleTxt">
                                                then
                                            </div>
                                            {/* change title to - set category to - add tag */}
                                            <ModularButton
                                                title={ruleParameters.actions[newRule.action].caption}
                                                style={ruleParamBtnStyle}
                                                ref={ruleActionBtnRefs.current[index]}
                                                popupAlignmentVertical='center'
                                                popupAlignmentHorizontal='center'
                                                popupContent={
                                                    <div className='ruleParamPopup'>
                                                        {ruleParameters.actions.map((action, i) => {
                                                            return <div
                                                                key={i}
                                                                className='ruleParamSelection'
                                                                onClick={() => {
                                                                    setNewRule((prev) => { return { ...prev, action: i } });
                                                                    if (action.key === 'addTag' || action.key === 'setTagCategory') {
                                                                        setNewRule((prev) => { return { ...prev, actionValue: tagCategories[0].category } });
                                                                    } else {
                                                                        setNewRule((prev) => { return { ...prev, actionValue: '' } })
                                                                    }
                                                                    ruleActionBtnRefs.current[index].current.closePopup()
                                                                }}
                                                            >
                                                                {action.caption}
                                                            </div>
                                                        })}
                                                    </div>
                                                }
                                            />
                                            {ruleParameters.actions[newRule.action].key === 'changeTitle' && <>
                                                {/* Change title input */}
                                                <HorizontalSpacer size={0.5} />
                                                <input
                                                    type='text'
                                                    className='settingsRuleInput'
                                                    placeholder='value…'
                                                    onChange={e => setNewRule((prev) => { return { ...prev, actionValue: e.target.value } })}
                                                />
                                            </>
                                            }
                                            {ruleParameters.actions[newRule.action].key === 'setTagCategory' && <>
                                                {/* Set tag category to selector */}
                                                <ModularButton
                                                    title={tagCategories.find(tag => tag.category === newRule.actionValue)?.category}
                                                    style={{
                                                        ...ruleParamBtnStyle,
                                                        color: tagColor(tagCategories.find(tag => tag.category === newRule.actionValue).color),
                                                        backgroundColor: tagBackgroundColor(tagCategories.find(tag => tag.category === newRule.actionValue).color),
                                                        border: '1px solid ' + tagBorderColor(tagCategories.find(tag => tag.category === newRule.actionValue).color)
                                                    }}
                                                    ref={ruleActionValueBtnRefs.current[index]}
                                                    popupAlignmentVertical='center'
                                                    popupAlignmentHorizontal='center'
                                                    popupContent={
                                                        <div className='ruleParamPopup'>
                                                            {tagCategories.map((tag, i) => {
                                                                return <div
                                                                    key={i}
                                                                    className='ruleParamSelection'
                                                                    style={{
                                                                        backgroundColor: tagBackgroundColor(tag.color),
                                                                        color: tagColor(tag.color),
                                                                    }}
                                                                    onClick={() => {
                                                                        setNewRule((prev) => { return { ...prev, actionValue: tag.category } });
                                                                        ruleActionValueBtnRefs.current[index].current.closePopup()
                                                                    }}
                                                                >
                                                                    {tag.category}
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                />
                                                <HorizontalSpacer size={0.25} />
                                            </>}
                                            {ruleParameters.actions[newRule.action].key === 'addTag' && <>
                                                {/* // Add tag selector */}
                                                <ModularButton
                                                    title={tagCategories.find(tag => tag.category === newRule.actionValue)?.category}
                                                    style={{
                                                        ...ruleParamBtnStyle,
                                                        color: tagColor(tagCategories.find(tag => tag.category === newRule.actionValue).color),
                                                        backgroundColor: tagBackgroundColor(tagCategories.find(tag => tag.category === newRule.actionValue).color),
                                                        border: '1px solid ' + tagBorderColor(tagCategories.find(tag => tag.category === newRule.actionValue).color)
                                                    }}
                                                    popupAlignmentVertical='center'
                                                    popupAlignmentHorizontal='center'
                                                    ref={ruleActionValueBtnRefs.current[index]}
                                                    popupContent={
                                                        <div className='ruleParamPopup'>
                                                            {tagCategories.map((tag, i) => {
                                                                return <div
                                                                    key={i}
                                                                    className='ruleParamSelection'
                                                                    style={{
                                                                        backgroundColor: tagBackgroundColor(tag.color),
                                                                        color: tagColor(tag.color),
                                                                        border: '1px solid ' + tagBorderColor(tag.color),
                                                                        margin: '0 0.25rem',
                                                                        cursor: 'pointer'
                                                                    }}
                                                                    onClick={() => {
                                                                        setNewRule((prev) => { return { ...prev, actionValue: tag.category } });
                                                                        ruleActionValueBtnRefs.current[index].current.closePopup()
                                                                    }}
                                                                >
                                                                    {tag.category}
                                                                </div>
                                                            })}
                                                        </div>
                                                    }
                                                />
                                                <HorizontalSpacer size={0.5} />
                                                <input
                                                    type='text'
                                                    className='settingsRuleInput'
                                                    placeholder='value…'
                                                    onChange={e => setNewRule((prev) => { return { ...prev, addTagValue: e.target.value } })}
                                                />
                                            </>}
                                            <ModularButton
                                                style={{ ...ruleParamBtnStyle, padding: '0.35rem 0.5rem', marginLeft: 'auto', marginRight: '0.2rem' }}
                                                iconEnd={<EnterKeyIcon size={oneRemToPixels} />}
                                                onClick={handleSaveNewRule}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className='settingsTagCategoriesFooter' >
                                <ModularButton
                                    title={addingNewRule ? 'Cancel' : 'New rule'}
                                    noGap={true}
                                    style={smallBtnStyle}
                                    onClick={addingNewRule ? resetNewRule : addNewRule}
                                    iconStart={<PlusCircleIcon size={oneRemToPixels} color='var(--text-color-subtle)' rotate={addingNewRule ? 45 : 0} />}
                                />
                            </div>
                        </div>

                        <VerticalSpacer size={3} />

                    </>}
                </>}

                <EasterEgg />

            </div>
        </div>
    </>
}

export default Settings