// Imports
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../mgt/firebase';
import * as firestoreAPI from '../mgt/firestoreAPI';
import * as TimeLogifyAPI from '../mgt/timeLogifyAPI';
import ModularButton from '../components/ModularButton';
import { DownArrowsIcon, DownloadIcon, PlayIcon, RefreshIcon, RightArrowsIcon } from '../components/Icons';
import { downloadUrl } from '../mgt/constants';
import HeaderBar from '../components/HeaderBar';
import VerticalSpacer from '../components/VerticalSpacer';


function SetupWizard() {
    // Authentication
    const [user, loading] = useAuthState(auth)

    // Navigation
    const navigate = useNavigate();
    const [wizardStep, setWizardStep] = useState(1)
    const wizardRef1 = useRef(null)
    const wizardRef2 = useRef(null)
    const wizardRef3 = useRef(null)
    const wizardRef4 = useRef(null)
    const wizardSteps = [wizardRef1, wizardRef2, wizardRef3, wizardRef4]

    // TimeLogify Status
    const [connectorAlive, setConnectorAlive] = useState(false)
    const [connectorChecked, setConnectorChecked] = useState(false)
    const [installerDownloaded, setInstallerDownloaded] = useState(false)
    const [isLoggingActive, setIsLoggingActive] = useState(false)
    const [checkConnectorIntervalActive, setCheckConnectorIntervalActive] = useState(false)
    const [originalHostId, setOriginalHostId] = useState('')
    const [originalHostName, setOriginalHostName] = useState('')
    const [hostName, setHostName] = useState('')


    // UNDER CONSTRUCTION:
    // *******************
    // useEffect(() => {
    //     navigate('/under-construction')
    // }, [])

    useEffect(() => {
        let interval

        if (checkConnectorIntervalActive) {
            interval = setInterval(() => {
                if (connectorAlive) {
                    setCheckConnectorIntervalActive(false)
                    clearInterval(interval)
                }
                checkTimeLogifyConnectorAlive()
            }, 5000)
        }

        return () => clearInterval(interval)
    }, [checkConnectorIntervalActive, connectorAlive])


    // Functions
    async function checkTimeLogifyConnectorAlive() {
        try {
            const connectorActive = await TimeLogifyAPI.isAlive()
            if (connectorActive) {
                checkTimeLogifyStatus()
            }

            setConnectorAlive(connectorActive)
        } catch (error) {
            setConnectorAlive(false)
        }
        setConnectorChecked(true)
    }

    async function checkTimeLogifyStatus() {
        try {
            const loggingActive = await TimeLogifyAPI.isLoggingActive()
            setIsLoggingActive(loggingActive)
        } catch (error) {
            setConnectorAlive(false)
            setIsLoggingActive(false)
        }
    }

    function handleDownloadInstaller() {
        const link = document.createElement('a')
        link.href = downloadUrl
        link.download = 'TimeLogifySetup.zip'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        setInstallerDownloaded(true)
        setCheckConnectorIntervalActive(true)

        handleContinueToStep(2)
    }

    async function connectTimeLogify() {
        const { hostId, hostName } = await TimeLogifyAPI.getHostInfo()
        setHostName(hostName)
        setOriginalHostName(hostName)
        setOriginalHostId(hostId)

        const authenticated = await TimeLogifyAPI.initialize(user.uid)
        if (!authenticated) {
            console.error('Not authenticated')
            return
        }

        if (!isLoggingActive) {
            const timeLogifyStarted = await TimeLogifyAPI.startLogging()
            if (!timeLogifyStarted) {
                console.error('TimeLogify not started')
                return
            }
        }

        await firestoreAPI.createUserComputerDocument(user.uid, hostId, hostName)

        setIsLoggingActive(true)
        handleContinueToStep(4)
    }

    async function handleFinalizeWizard() {
        if (hostName !== originalHostName) {
            await firestoreAPI.updateUserComputerDocument(user.uid, originalHostId, hostName)
        }

        navigate(`/dashboard`)
    }

    function handleContinueToStep(step) {
        if (step > wizardStep)
            setWizardStep(step)

        setTimeout(() => {
            scrollToNextStep(step)
        }, 1);
    }

    function scrollToNextStep(step) {
        if (step === 1) {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            return
        }

        wizardSteps[step - 1].current.scrollIntoView({ behavior: 'smooth' })
    }


    // Layout
    if (loading) return <>
        <HeaderBar />
        <div className='loadingText'>Loading...</div>
        <div className='loadingSpinner' />
    </>

    return <>
        <HeaderBar />

        <div className='setupWizard'>

            <div className='homeSection' ref={wizardRef1} >
                <div className='homeSubtitle'>👋 Welcome to TimeLogify!</div>
                <div className='homeSpacer' />
                <div className='homeSubtext'>Download the installer below to start logging your time on this computer.</div>

                <div className='verticalSpacerLarge' />
            </div>
            {
                installerDownloaded ?
                    <ModularButton
                        title='Continue'
                        onClick={() => handleContinueToStep(2)}
                        border={true}
                        iconEnd={<DownArrowsIcon size={24} />}
                        style={{ display: wizardStep >= 1 ? 'flex' : 'none' }}
                    />
                    :
                    <ModularButton
                        title='Download installer'
                        onClick={handleDownloadInstaller}
                        border={true}
                        iconEnd={<DownloadIcon size={24} />}
                    />
            }

            <div className='homeSection' ref={wizardRef2} style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />

                <div className='homeSubtitle'>{connectorAlive ? '✅ Installation complete' : '⏳ Waiting for connection...'}</div>
                <div className='homeSpacer' style={{ width: !connectorAlive ? '14rem' : '21rem' }} />
                <div className='homeSubtext'>{connectorAlive ? 'TimeLogify was succesfully installed!' : 'Please open the installer to proceed with the installation.'}</div>
                {!connectorAlive && <div className='homeSubtext'>You might need to select '<span style={{ textDecoration: 'underline' }}>More info</span>' and then '<span style={{ fontWeight: '600' }}>Run anyway</span>' to run the installer.</div>}

                <div className='verticalSpacerLarge' />
            </div>
            {
                !connectorAlive ?
                    <ModularButton
                        title='Check again'
                        onClick={checkTimeLogifyStatus}
                        border={false}
                        iconEnd={<RefreshIcon size={24} />}
                        style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}
                    />
                    :
                    <ModularButton
                        title='Continue'
                        onClick={() => handleContinueToStep(3)}
                        border={true}
                        iconEnd={<DownArrowsIcon size={24} />}
                        style={{ display: wizardStep >= 2 ? 'flex' : 'none' }}
                    />
            }


            <div className='homeSection' ref={wizardRef3} style={{ display: wizardStep >= 3 ? 'flex' : 'none' }}>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />

                <div className='homeSubtitle'>Start logging on this computer</div>
                <div className='homeSpacer' style={{ width: '28rem' }} />
                <div className='homeSubtext'>Click the button below to open TimeLogify and automatically start logging the time spent on this computer.</div>

                <div className='verticalSpacerLarge' />
            </div>
            {
                !isLoggingActive ?
                    <ModularButton
                        title='Start logging'
                        onClick={connectTimeLogify}
                        iconStart={<PlayIcon size={24} />}
                        border={true}
                        style={{ display: wizardStep >= 3 ? 'flex' : 'none' }}
                    />
                    :
                    <ModularButton
                        title='Continue'
                        onClick={() => handleContinueToStep(4)}
                        border={true}
                        iconEnd={<DownArrowsIcon size={24} />}
                        style={{ display: wizardStep >= 3 ? 'flex' : 'none' }}
                    />
            }


            <div className='homeSection' ref={wizardRef4} style={{ display: wizardStep >= 4 ? 'flex' : 'none' }}>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />

                <div className='homeSubtitle'>All set up!</div>
                <div className='homeSpacer' style={{ width: '35rem' }} />
                <div className='homeSubtext'>Optionally, you can rename this computer to something you recognize.</div>
                <div className='homeSubtext'>This can be changed later at any time.</div>

                <VerticalSpacer />
                <VerticalSpacer />

                <input
                    placeholder='Computer name'
                    className='hostNameInput'
                    value={hostName}
                    onChange={(e) => setHostName(e.target.value)}
                />

                <div className='verticalSpacerLarge' />
            </div>
            <ModularButton
                title={hostName !== originalHostName ? 'Save changes' : 'Continue'}
                onClick={handleFinalizeWizard}
                iconEnd={<RightArrowsIcon size={24} />}
                border={true}
                style={{ display: wizardStep >= 4 ? 'flex' : 'none' }}
            />

            {(wizardStep > 1) && <>
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
                <div className='verticalSpacerLarge' />
            </>}
        </div>
    </>
}

export default SetupWizard