// Imports
import React, { useEffect, useRef, useState } from 'react'

// Components
import { CheckmarkIcon, MaximizeIcon, MinimizeIcon, PlusCircleIcon } from './Icons'

// Functions
import * as firebaseAPI from '../mgt/firestoreAPI'
import { getFeedbackClosed, setFeedbackClosed } from '../mgt/cachingMgt'
import ModularButton from './ModularButton'

const FeedbackForm = ({ user }) => {
    const [feedback, setFeedback] = useState('')
    const [sendFeedbackBtnTxt, setSendFeedbackBtnTxt] = useState('Send feedback')
    const [sendFeedbackBtnIcon, setSendFeedbackBtnIcon] = useState(null)
    const [isOpen, setIsOpen] = useState(true)
    const [openCloseIcon, setOpenCloseIcon] = useState(<MinimizeIcon />)
    const feedbackFormRef = useRef()

    useEffect(() => {
        if (getFeedbackClosed()) {
            setOpenCloseIcon(<MaximizeIcon />)
            setIsOpen(false)
        }
    }, [])

    async function handleSendFeedback() {
        if (!feedback) return
        if (sendFeedbackBtnTxt === 'Feedback sent!') return

        setSendFeedbackBtnIcon(<div className='loadingSpinner' />)

        const result = await firebaseAPI.insertMessage(user.uid, feedback)

        if (result.success) {
            setSendFeedbackBtnTxt('Feedback sent!')
            setSendFeedbackBtnIcon(<CheckmarkIcon color='rgb(0, 255, 0)' />)
            setTimeout(() => {
                setSendFeedbackBtnTxt('Send feedback')
                setSendFeedbackBtnIcon(null)
            }, 3000)
            setFeedback('')
        } else {
            setSendFeedbackBtnTxt('Something went wrong')
            setSendFeedbackBtnIcon(<PlusCircleIcon rotate={45} color='rgb(255, 0, 0)' />)
            setTimeout(() => {
                setSendFeedbackBtnTxt('Send feedback')
                setSendFeedbackBtnIcon(null)
            }, 3000)
        }
    }

    function handleOpenClose() {
        if (isOpen) {
            setOpenCloseIcon(<MaximizeIcon />)
            setIsOpen(!isOpen)
            setFeedbackClosed(true)
        } else {
            setOpenCloseIcon(<MinimizeIcon />)
            setTimeout(() => {
                setIsOpen(!isOpen)
                setFeedbackClosed(false)
            }, 500)
        }

        feedbackFormRef.current.className = isOpen ? 'feedbackContainer feedbackContainerClosed' : 'feedbackContainer'
    }

    return <>
        <div className={!isOpen ? 'feedbackContainer feedbackContainerClosed' : 'feedbackContainer'} ref={feedbackFormRef}>
            <div className='feedbackCloseIcon' onClick={handleOpenClose}>
                {openCloseIcon}
            </div>
            {isOpen && <>
                <div className='feedbackTitle'>Help TimeLogify grow!</div>
                <textarea
                    className='feedbackInput'
                    placeholder={`How would you improve TimeLogify? What is missing or should be changed? Let us know!`}
                    onChange={(e) => setFeedback(e.target.value)}
                    value={feedback}
                />
                <ModularButton
                    title={sendFeedbackBtnTxt}
                    onClick={handleSendFeedback}
                    border={true}
                    style={{ width: 'calc(100% - 2rem)', justifyContent: 'center' }}
                    iconEnd={sendFeedbackBtnIcon}
                />
            </>}
        </div>
    </>
}

export default FeedbackForm