import React, { useState } from 'react'
import ModularButton from '../components/ModularButton'

const EasterEgg = () => {
    const [dontClickVisible, setDontClickVisible] = useState(true)

    function handleDontClick() {
        // Make the whole document do a barrel roll and shrink, then do it backwards
        document.body.style.transition = 'all 0.4s'
        document.body.style.transform = 'rotate(1360deg) scaleX(0.005) scaleY(0.01)'
        setTimeout(() => {
            document.body.style.transition = 'all 0.1s'
            document.body.style.transform = 'rotate(0deg) scale(1)'
        }, 400)
        setDontClickVisible(false)
    }

    if (dontClickVisible) return (
        <ModularButton
            title="Don't click here"
            onClick={handleDontClick}
            border={true}
            style={{
                fontSize: '8px',
                fontWeight: '100',
                padding: '0.2rem',
                position: 'absolute',
                right: '5px',
                bottom: '5px',
            }}
        />
    )
}

export default EasterEgg