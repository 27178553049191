import React, { useEffect, useState } from 'react'
import { handleSignInWithEmail, handleSignInWithPopupGoogle } from '../mgt/authMgt'
import { useLocation, useNavigate } from 'react-router-dom'
import { GoogleIcon, RevealIcon } from '../components/Icons'
import { auth } from '../mgt/firebase'
import { useAuthState } from 'react-firebase-hooks/auth'
import '../styles/App.css'
import ModularButton from '../components/ModularButton'
import HeaderBar from '../components/HeaderBar';
import LoadingText from '../components/LoadingText'
import VerticalSpacer from '../components/VerticalSpacer'

function Login() {
    const [user, loading] = useAuthState(auth)
    const navigate = useNavigate()
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const redirect = queryParams.get('redirect') || false;
    const [emailInput, setEmailInput] = useState('')
    const [passwordInput, setPasswordInput] = useState('')
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [emailError, setEmailError] = useState('')
    const [emailErrText, setEmailErrText] = useState('')
    const [passwordError, setPasswordError] = useState('')
    const [passwordErrText, setPasswordErrText] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if (loading) return
        if (redirect && user) {
            const newRedirect = redirect.split('?')[0]
            navigate(newRedirect)
        }

        if (user) {
            navigate('/dashboard')
        }
    }, [loading, user])

    const tryLoginWithGoogle = async () => {
        const userDoc = await handleSignInWithPopupGoogle()
        if (userDoc) {
            handleLoginSuccess(userDoc.id)
        }
    }

    const tryLoginWithEmail = async () => {
        if (!checkEmailInput(true)) return
        if (!checkPasswordInput(true)) return

        const result = await handleSignInWithEmail(emailInput, passwordInput)

        if (result.success) {
            if (result.userDoc) {
                handleLoginSuccess(result.userDoc.id)
            }
        } else {
            setPasswordError('show')
            setPasswordErrText(result.errorMessage)
            return
        }
    }

    function checkEmailInput(checkEmpty) {
        if (emailInput === '') {
            if (checkEmpty === true) {
                setEmailError('show')
                setEmailErrText('Please enter your email')
                return false
            } else {
                setEmailError('')
                return true
            }
        }

        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (emailRegex.test(emailInput)) {
            setEmailError('')
            return true
        } else {
            setEmailError('show')
            setEmailErrText('Please enter a valid email address')
            return false
        }
    }

    function checkPasswordInput(checkEmpty) {
        if (passwordInput === '') {
            if (checkEmpty === true) {
                setPasswordError('show')
                setPasswordErrText('Please enter your password')
                return false
            } else {
                setPasswordError('')
                return true
            }
        }
        return true
    }

    function handleLoginSuccess(userId) {
        if (redirect) {
            navigate(redirect)
        } else {
            navigate(`/dashboard`)
        }
    }

    function switchToRegister() {
        if (redirect)
            navigate(`/register?redirect=${redirect}`)
        else
            navigate('/register')
    }

    if (loading) return <>
        <HeaderBar />
        <LoadingText />
    </>

    return <>
        <HeaderBar />

        <div className='loginSection'>
            <div className='homeSubtitle'>Log into your account</div>
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='homeSpacer' />
                <span
                    tabIndex={0}
                    className='loginRegisterSwitchText'
                    onClick={switchToRegister}>
                    {'Create a new account >'}
                </span>
            </div>

            <VerticalSpacer />


            <div className='registerInputLbl'>Email</div>
            <div className='registerInputWrapper'>
                <input
                    placeholder='Enter your email'
                    className='registerInput'
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    onBlur={checkEmailInput}
                    autoFocus
                />
                <div className={`inputErrorLbl ${emailError}`}>{emailErrText}</div>
            </div>

            <div className='registerInputLbl'>Password</div>
            <div className='registerInputWrapper'>
                <input
                    placeholder='Enter your password'
                    className='registerInput'
                    value={passwordInput}
                    onChange={(e) => setPasswordInput(e.target.value)}
                    onBlur={checkPasswordInput}
                    type={passwordVisible ? 'text' : 'password'}
                    onKeyDown={(e) => { if (e.key === 'Enter') tryLoginWithEmail() }}
                />
                <div className='showPasswordIcon' onClick={() => setPasswordVisible(!passwordVisible)}>
                    <RevealIcon size={30} transparent={true} grey={!passwordVisible} />
                </div>
            </div>
            <div className={`inputErrorLbl ${passwordError}`}>{passwordErrText}</div>

            <VerticalSpacer />

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <ModularButton
                    title='Log in'
                    onClick={tryLoginWithEmail}
                    border={false}
                    wide={true}
                />
                <VerticalSpacer />
                <ModularButton
                    title='Continue with Google'
                    onClick={tryLoginWithGoogle}
                    border={true}
                    wide={true}
                    iconEnd={<GoogleIcon size={20} />}
                />
            </div>

            <div className='verticalSpacerLarge' />
            <VerticalSpacer />
            <VerticalSpacer />

            <ModularButton
                title='Go back'
                onClick={() => navigate('/')}
                border={false}
                wide={true}
            />

        </div>
    </>
}

export default Login