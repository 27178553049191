import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { remToPixels } from '../mgt/layoutMgt';

const ModularButton = forwardRef((props, ref) => {
    const [menuVisible, setMenuVisible] = useState(null);
    const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
    const [reopenTimeout, setReopenTimeout] = useState(false);
    const [fullScreenPopupVisible, setFullScreenPopupVisible] = useState(false);
    const popupRef = useRef(null);
    const buttonRef = useRef(null);

    useImperativeHandle(ref, () => ({
        closePopup: () => { setMenuVisible(false); setFullScreenPopupVisible(false); }
    }));

    // New props for positioning
    const { popupAlignmentVertical = 'top', popupAlignmentHorizontal = 'left' } = props;

    let className = 'modularButton';
    if (props.border) className += ' modularButtonBorder';
    if (props.narrow) className += ' modularButtonNarrow';
    if (props.wide) className += ' modularButtonWide';
    if (props.noGap) className += ' modularButtonNoGap';
    if (props.isActive) className += ' modularButtonActive';
    if (props.glowIconOnHover) className += ' modularButtonGlowIconOnHover';
    if (props.forTags) className += ' settingsTagCategoryName';
    const resizeObserver = useRef(null);

    // Show popup menu
    const handlePopupUpdate = (event) => {
        const rect = event.currentTarget.getBoundingClientRect();
        let top = rect.bottom; // Default to below the button
        let left = rect.left;  // Default to align left

        if (popupAlignmentVertical === 'top') {
            top = rect.top; // Position above the button
        }

        if (popupAlignmentHorizontal === 'right') {
            left = rect.right; // Position aligned to the right
        }

        setMenuPosition({ top, left });
        setMenuVisible(!menuVisible);
    };

    // Adjust the popup position once it's rendered and its width is known
    useEffect(() => {
        if (!menuVisible) {
            if (props.onClosePopupFunction) {
                props.onClosePopupFunction();
            }
        }

        if (menuVisible && reopenTimeout) {
            setMenuVisible(false);
            return;
        }

        setReopenTimeout(true);
        setTimeout(() => setReopenTimeout(false), 1000);

        if (!menuVisible) return;

        function updatePosition() {
            if (!popupRef.current || !buttonRef.current) return;

            const popupWidth = popupRef.current.offsetWidth;
            const popupHeight = popupRef.current.offsetHeight;
            const margin = remToPixels(0.5);

            let left = menuPosition.left;
            let top = menuPosition.top;


            if (popupAlignmentVertical === 'top') {
                top -= popupHeight + margin * 1.5; // Position above the button
            } else if (popupAlignmentVertical === 'bottom') {
                top -= margin / 3; // Position below the button
            } else if (popupAlignmentVertical === 'center') {
                top -= (popupHeight + getHeight()) / 2 + margin; // Center the popup
            }

            if (popupAlignmentHorizontal === 'right') {
                left -= popupWidth; // Align right edge with button's right edge
            } else if (popupAlignmentHorizontal === 'center') {
                left -= (popupWidth - getWidth()) / 2; // Center the popup
            } else {
                left -= margin / 3; // Align left edge with button's left edge
            }

            setMenuPosition({ top: Math.max(0, top), left: Math.max(0, left) });
        }

        updatePosition();

        // Set up the resize observer
        resizeObserver.current = new ResizeObserver(() => {
            updatePosition();
        });
        resizeObserver.current.observe(popupRef.current);

        return () => {
            if (resizeObserver.current) {
                resizeObserver.current.disconnect();
            }
        };
    }, [menuVisible]); // Trigger only when `menuVisible` changes

    // Close the popup when clicking outside or pressing Esc
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuVisible && !popupRef.current.contains(event.target)) {
                setMenuVisible(false);
            }
        };
        const handleClickEscape = (event) => {
            if (menuVisible && event.key === 'Escape') {
                setMenuVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickEscape);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('keydown', handleClickEscape);
        }
    }, [menuVisible]);

    useEffect(() => {
        function closePopup() {
            setFullScreenPopupVisible(false);
        }

        function outsideClickHandler(event) {
            if (event.target.className === 'fullScreenPopup') {
                closePopup();
            }
        }

        function pressEscapeHandler(event) {
            if (event.key === 'Escape') {
                closePopup();
            }
        }

        if (fullScreenPopupVisible) {
            document.addEventListener('click', outsideClickHandler);
            document.addEventListener('keydown', pressEscapeHandler);
        } else {
            document.removeEventListener('click', outsideClickHandler);
            document.removeEventListener('keydown', pressEscapeHandler);
        }

        return () => {
            document.removeEventListener('click', outsideClickHandler);
            document.removeEventListener('keydown', pressEscapeHandler);
        };
    }, [fullScreenPopupVisible]);

    function getHeight() {
        return buttonRef.current ? buttonRef.current.offsetHeight : 0;
    }

    function getWidth() {
        return buttonRef.current ? buttonRef.current.offsetWidth : 0;
    }

    const handleButtonClick = (event) => {
        handleblur();

        if (props.popupContent) {
            handlePopupUpdate(event);
        }

        if (props.fullScreenPopupContent) {
            setFullScreenPopupVisible(true);
        }

        props?.onClick && props.onClick(event);
    };

    const handleblur = () => {
        buttonRef.current.blur();
    };

    return (
        <>
            <button
                id={props.id}
                className={className}
                onClick={handleButtonClick}
                style={props.style}
                ref={buttonRef}
                title={props.tooltip}
                key={props.index}
            >
                {props.iconStart}
                {props.title}
                {props.iconEnd}
            </button>
            {/* Popup Menu */}
            {menuVisible && (
                <div
                    ref={popupRef}
                    className='buttonPopupMenu'
                    style={{
                        top: menuPosition.top,
                        left: menuPosition.left,
                        minWidth: 'auto',
                        whiteSpace: 'nowrap', // Prevent text from wrapping
                    }}
                >
                    {props.popupContent}
                </div>
            )}
            {/* Full screen popup */}
            {fullScreenPopupVisible && (
                <div className={'fullScreenPopup'}>
                    {props.fullScreenPopupContent}
                </div>
            )}
        </>
    );
});

export default ModularButton;
