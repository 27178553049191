import React, { useEffect, useRef, useState } from 'react'
import VerticalSpacer from '../../components/VerticalSpacer'
import { ExcelIcon, JiraIcon, TagHoleCircleIcon } from '../../components/Icons'
import { remToPixels } from '../../mgt/layoutMgt'
import { getDefaultTagColor, tagBackgroundColor, tagBorderColor, tagColor } from '../../mgt/constants'
import ModularButton from '../../components/ModularButton'
import { useGlobals } from '../../mgt/GlobalsProvider'
import LoadingText from '../../components/LoadingText'
import * as FirestoreAPI from '../../mgt/firestoreAPI'

const Integrations = () => {
    const oneRemToPx = remToPixels(1)
    const { userDoc, setUserDoc } = useGlobals()
    const [tagForJira, setTagForJira] = useState('')
    const [baseUrlForJira, setBaseUrlForJira] = useState('')
    const [jiraEmail, setJiraEmail] = useState('')
    const [jiraToken, setJiraToken] = useState('')
    const tagForJiraBtnRef = useRef()

    function saveEmailForJira() {
        if (!userDoc) return
        if (jiraEmail === userDoc.settings.integrations.jira.email) return

        const newUserDoc = { ...userDoc }
        newUserDoc.settings.integrations.jira.email = jiraEmail
        setUserDoc(newUserDoc)

        FirestoreAPI.modifyUserDocument(newUserDoc)
    }

    function saveTokenForJira() {
        if (!userDoc) return
        if (jiraToken === userDoc.settings.integrations.jira.token) return

        const newUserDoc = { ...userDoc }
        newUserDoc.settings.integrations.jira.token = jiraToken
        setUserDoc(newUserDoc)

        FirestoreAPI.modifyUserDocument(newUserDoc)
    }

    function saveBaseUrlForJira() {
        if (!userDoc) return
        if (baseUrlForJira === userDoc.settings.integrations.jira.issueBaseUrl) return

        let cleanBaseUrlForJira = baseUrlForJira.trim()
        if (cleanBaseUrlForJira !== '' && !cleanBaseUrlForJira.endsWith('/')) cleanBaseUrlForJira += '/'

        const newUserDoc = { ...userDoc }
        newUserDoc.settings.integrations.jira.issueBaseUrl = cleanBaseUrlForJira
        setUserDoc(newUserDoc)

        FirestoreAPI.modifyUserDocument(newUserDoc)
    }

    function saveTagForJira(tagCategory) {
        if (!userDoc) return
        if (tagCategory === userDoc.settings.integrations.jira.tagCategory) return

        const newUserDoc = {
            ...userDoc,
        }
        newUserDoc.settings.integrations.jira.tagCategory = tagCategory
        setUserDoc(newUserDoc)

        FirestoreAPI.modifyUserDocument(newUserDoc)
    }

    function getTagColorIfExists() {
        if (!userDoc || tagForJira === '') return getDefaultTagColor()

        const tag = userDoc.settings.tags.find(tag => tag.category === tagForJira)
        return tag ? tag.color : getDefaultTagColor()
    }

    useEffect(() => {
        if (!userDoc) return

        setTagForJira(userDoc.settings.integrations.jira.tagCategory)
        setBaseUrlForJira(userDoc.settings.integrations.jira.issueBaseUrl)
        setJiraEmail(userDoc.settings.integrations.jira.email)
        setJiraToken(userDoc.settings.integrations.jira.token)
    }, [userDoc])

    if (!userDoc) return <LoadingText />

    return <>
        <VerticalSpacer size={5} />

        {/* <div className='settingsTitle'><ExcelIcon size={2 * oneRemToPx} />Excel templates</div>
        <div className='settingsSubtitle'>Create custom templates to export your logs to excel in a specified format</div>
        <VerticalSpacer size={2} /> */}

        <div className='settingsTitle'><JiraIcon size={2 * oneRemToPx} />Jira</div>
        <div className='settingsSubtitle'>Integrate with Jira to automatically log your work hours</div>

        <VerticalSpacer size={1} />
        <div className='settingsLbl'>Enter your Jira email address</div>
        <input
            type='text'
            value={jiraEmail}
            className='settingsInput'
            placeholder='your@email.com'
            onChange={(e) => setJiraEmail(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    e.target.blur()
                }
            }}
            onBlur={saveEmailForJira}
        />

        <VerticalSpacer size={1} />
        <div className='settingsLbl'>Enter your Jira API token.</div>
        <input
            type='password'
            value={jiraToken}
            className='settingsInput'
            placeholder='Your API token'
            onChange={(e) => setJiraToken(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    e.target.blur()
                }
            }}
            onBlur={saveTokenForJira}
        />
        <div className='settingsLbl' style={{ fontSize: '0.9rem' }}>Don't have an API token yet? You can create one <a href='https://id.atlassian.com/manage-profile/security/api-tokens' target='_blank' rel='noreferrer'>here</a></div>

        <VerticalSpacer size={1} />
        <div className='settingsLbl'>Enter the base URL for Jira</div>
        <input
            type='text'
            value={baseUrlForJira}
            className='settingsInput'
            placeholder='https://your-company.atlassian.net/browse/'
            onChange={(e) => setBaseUrlForJira(e.target.value)}
            onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === 'Tab') {
                    e.target.blur()
                }
            }}
            onBlur={saveBaseUrlForJira}
        />
        <div className='settingsLbl' style={{ fontSize: '0.9rem' }}>It should look something like this: https://your-company.atlassian.net/</div>

        <VerticalSpacer size={1} />
        <div className='settingsLbl'>Select the tag you use for Jira issue numbers</div>
        <ModularButton
            title={tagForJira || 'Select a tag'}
            ref={tagForJiraBtnRef}
            onClick={() => { }}
            forTags={true}
            iconStart={<TagHoleCircleIcon
                size={oneRemToPx / 1.5}
                color={getTagColorIfExists()}
            />}
            style={{
                color: tagColor(getTagColorIfExists()),
                backgroundColor: tagBackgroundColor(getTagColorIfExists()),
                border: '1px solid ' + tagBorderColor(getTagColorIfExists()),
                marginLeft: '5rem',
            }}
            popupAlignmentVertical='center'
            popupAlignmentHorizontal='center'
            popupContent={
                <div className='ruleParamPopup'>
                    {userDoc.settings.tags.map((tag, i) => {
                        return <div
                            key={i}
                            className='ruleParamSelection'
                            style={{
                                backgroundColor: tagBackgroundColor(tag.color),
                                color: tagColor(tag.color),
                            }}
                            onClick={() => {
                                saveTagForJira(tag.category);
                                tagForJiraBtnRef.current.closePopup()
                            }}
                        >
                            {tag.category}
                        </div>
                    })}
                </div>
            }
        />

        <VerticalSpacer size={5} />
    </>
}

export default Integrations