import React, { createContext, useContext, useEffect, useState } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import * as firestoreAPI from './firestoreAPI'
import { auth } from './firebase'
import { useNavigate } from 'react-router-dom'

const GlobalsContext = createContext()

export const useGlobals = () => {
    return useContext(GlobalsContext)
}

export const GlobalsProvider = ({ children }) => {
    const [user, loading] = useAuthState(auth)
    const [logs, setLogs] = useState([])
    const [filteredLogs, setFilteredLogs] = useState([])
    const [currentSort, setCurrentSort] = useState({ column: 1, desc: true })
    const [userDoc, setUserDoc] = useState(null)
    const [selectedHostId, setSelectedHostId] = useState('')
    const [selectedLines, setSelectedLines] = useState([])

    useEffect(() => {
        if (loading) return
        if (!user) {
            return
        }

        getUserDoc()
    }, [loading, user])

    async function getUserDoc() {
        const userDoc = await firestoreAPI.getUserDocument(user.uid)
        console.log('User:', userDoc.id)

        setUserDoc(userDoc)
        return userDoc
    }

    function updateSorting(newSort) {
        setFilteredLogs(prev => {
            const newLogs = [...prev]

            if (!newSort) {
                newSort = currentSort
            }

            newLogs.sort((a, b) => {
                switch (newSort.column) {
                    case 0:
                        return newSort.desc ? b.startTime.seconds - a.startTime.seconds : a.startTime.seconds - b.startTime.seconds
                    case 1:
                        return newSort.desc ? b.startTime.seconds - a.startTime.seconds : a.startTime.seconds - b.startTime.seconds
                    case 2:
                        return newSort.desc ? b.title.localeCompare(a.title) : a.title.localeCompare(b.title)
                    default:
                        return 0
                }
            })

            return newLogs
        })
    }

    const exports = {
        logs, setLogs,
        filteredLogs, setFilteredLogs,
        currentSort, setCurrentSort, updateSorting,
        userDoc, setUserDoc, getUserDoc, user, loading,
        selectedHostId, setSelectedHostId,
        selectedLines, setSelectedLines,
    }

    return (
        <GlobalsContext.Provider value={exports}>
            {children}
        </GlobalsContext.Provider>
    )
}
