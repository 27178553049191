import { Timestamp } from 'firebase/firestore';

function getDurationString(totalMins, full = false) {
    const hours = Math.floor(totalMins / 60);
    const minutes = totalMins % 60;

    if (totalMins >= 60) {
        if (full) {
            const hourText = hours === 1 ? 'hour' : 'hours';
            const minuteText = minutes === 1 ? 'minute' : 'minutes';
            return minutes > 0
                ? `${hours} ${hourText} ${minutes} ${minuteText}`
                : `${hours} ${hourText}`;
        } else {
            return minutes > 0
                ? `${hours}h ${minutes}m`
                : `${hours}h`;
        }
    } else {
        if (full)
            return minutes === 1 ? `${totalMins} minute` : `${totalMins} minutes`;
        else
            return `${totalMins}m`;
    }
}

function convertDateToTimestamp(date) {
    // const seconds = date.getTime() / 1000;
    // const nanoseconds = (date.getTime() % 1000) * 1000000;
    // return { seconds, nanoseconds };
    const timestamp = Timestamp.fromDate(date);
    return timestamp;
}

function convertTimestampToDate(timestamp) {
    const { seconds, nanoseconds } = timestamp;
    const milliseconds = seconds * 1000 + nanoseconds / 1000000;
    return new Date(milliseconds);
}

function convertDateStrToTimestamp(dateStr) {
    const date = new Date(dateStr);
    return convertDateToTimestamp(date);
}

function convertToLocalDate(date) {
    const offset = date.getTimezoneOffset();
    const localDate = new Date(date.getTime() - offset * 60000);
    return localDate;
}

function getDocumentIdFromDate(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');
    return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
}

/**
 * Converts a Date object to a formatted day key string (YYYY-MM-DD).
 * 
 * @param {Date} date - The date to convert.
 * @returns {string} The formatted day key string.
 */
function getDayKeyFromDate(date) {
    // YYYY-MM-DD in UTC
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // getUTCMonth() is zero-based
    const day = String(date.getUTCDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
}

export {
    getDurationString,
    convertDateToTimestamp,
    convertTimestampToDate,
    convertDateStrToTimestamp,
    getDocumentIdFromDate,
    getDayKeyFromDate,
    convertToLocalDate
};
