import React, { useEffect, useState } from 'react'
import { getDurationString } from '../mgt/timeMgt'
import { useGlobals } from '../mgt/GlobalsProvider'

const SummarizedLogsTable = () => {
    const [summary, setSummary] = useState({})
    const { filteredLogs: logs } = useGlobals()

    useEffect(() => {
        if (!logs?.length) {
            setSummary({})
            return
        }

        const summary = {}
        logs.forEach(logDoc => {
            logDoc.logs.forEach(logLine => {
                const title = logLine.title
                if (title in summary) {
                    summary[title] += 1
                } else {
                    summary[title] = 1
                }
            }
            )
        })
        setSummary(summary)
    }, [logs])

    return <>
        <div className='summarizedLogsContainer'>
            <div className='summarizedLogsHeader'>
                <div className='summarizedLogsHeaderTitle'>Total</div>
                <div className='summarizedLogsHeaderTitle'>Active window</div>
            </div>
            <div className='summarizedLogsBody'>
                {Object.keys(summary)
                    .sort((a, b) => summary[b] - summary[a])
                    .map((key, index) => {
                        const totalMins = summary[key]
                        const totalStr = getDurationString(totalMins)
                        const title = key

                        return <div key={index} className='summarizedLogsBodyRow'>
                            <div className='summarizedLogsBodyTitle'>{totalStr}</div>
                            <div className='summarizedLogsBodyTitle'>{title}</div>
                        </div>
                    })}
            </div>
        </div>
    </>
}

export default SummarizedLogsTable
