import React from 'react'
import { useNavigate } from 'react-router-dom'
import ModularButton from '../components/ModularButton';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from '../mgt/firebase';
import HeaderBar from '../components/HeaderBar';
import LoadingText from '../components/LoadingText';
import VerticalSpacer from '../components/VerticalSpacer';

function NotFound() {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth)

    function goToHome() {
        navigate('/');
    }

    if (loading) return <>
        <HeaderBar />
        <LoadingText />
    </>

    return <>
        <HeaderBar />

        <div className='homeSection'>
            <div className='homeSubtitle'>Huh, how did you get here?</div>
            <div className='homeSpacer' />
            <div className='homeSubtext'>I appreciate your curiosity but unfortunately this page does not exist yet.</div>
        </div>

        <div className='verticalSpacerLarge' />
        <VerticalSpacer />

        <ModularButton
            title='Go back home'
            onClick={goToHome}
            border={true}
        />
    </>
}

export default NotFound